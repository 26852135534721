import { FIELD_NAMES } from "constant/Pricer/PricerTable";

export const getTenorValueFromString = (value) => {
  let formattedValue = value.trim();
  const values = formattedValue.split(" ");
  if (values.length > 0) {
    formattedValue = values[0].trim();
  }
  return formattedValue;
};

const getStrikeValueFromString = (value) => {
  let formattedValue = value;
  if (isNaN(Number(value))) {
    const values = formattedValue.split("/");
    formattedValue = values[0];
  }
  return Number(formattedValue);
};

export const getPeriodType = (value) => {
  const period = value.split("-")[0];
  //########## Special case for Bespoke types
  if (value.includes(",")) return "Months";
  //############
  if (QUARTERS.includes(period)) return "Quarters";
  if (MONTHS.includes(period)) return "Months";
  if (SEASONS.includes(period)) return "Seasons";
  if (period.includes("Cal")) return "Calendars";
  return "error";
};

const getPeriodTypeValue = (value) => {
  return value.split("-")[0];
};

const compareCondition = (valueA, valueB) => {
  return valueA === valueB ? 0 : valueA > valueB ? 1 : -1;
};
 
export const tenorComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  valueA = valueA ?? "";
  valueB = valueB ?? "";

  let formattedValueA = valueA ?? "";
  let formattedValueB = valueB ?? "";
  
  if (formattedValueA === "" && nodeA.group) {
    const nodeAFirstChild = nodeA.childrenAfterAggFilter[0];
    const nodeAFirstChildData = nodeAFirstChild.group
      ? nodeAFirstChild.aggData
      : nodeAFirstChild.data;
    formattedValueB = nodeAFirstChildData.tenor;
  } 
  if (formattedValueB === "" && nodeB.group) {
    const nodeBFirstChild = nodeB.childrenAfterAggFilter[0];
    const nodeBFirstChildData = nodeBFirstChild.group
      ? nodeBFirstChild.aggData
      : nodeBFirstChild.data;
    formattedValueB = nodeBFirstChildData.tenor;
  }

  const nodeAData = nodeA.group ? nodeA.aggData : nodeA.data;
  const nodeBData = nodeB.group ? nodeB.aggData : nodeB.data;
  //We only want to sort bu the main group rows.
  //This keeps the order of the sublegs and legs
  if (nodeAData.summary_id === nodeBData.summary_id) return 0;

  //if the summary rows has more than one value then we need to sort by the first value
  formattedValueA = formattedValueA.split("/")[0].trim();
  formattedValueB = formattedValueB.split("/")[0].trim();
  // remove the Month from calendars and seasons (Mar)
  const periodStringA = getTenorValueFromString(formattedValueA);
  const periodStringB = getTenorValueFromString(formattedValueB);

  // gets type if calendar, quarter, month, season
  const periodTypeA = getPeriodType(periodStringA);
  const periodTypeB = getPeriodType(periodStringB);


  // gets value without the year Mar-22 -> Mar
  const periodValueA = getPeriodTypeValue(periodStringA);
  const periodValueB = getPeriodTypeValue(periodStringB);

  if (periodTypeA === "error" || periodTypeB === "error") {
    console.log("tenor sort error");
    return 0;
  }

  /*
    1. compare period type
    -----
    2. grouping
    ------
    */
  const periodTypeAPriortiy = PERIODS[periodTypeA]["priority"];
  const periodTypeBPriortiy = PERIODS[periodTypeB]["priority"];
  const hasSamePeriodType = periodTypeA === periodTypeB;


  // 1. compare period type

  if (!hasSamePeriodType) {
    //compare period type
    return compareCondition(periodTypeAPriortiy, periodTypeBPriortiy);
  }
  // 2. compare chronologically
  let yearA = periodStringA.split("-")[1];
  let yearB = periodStringB.split("-")[1];
  const hasSameYear = yearA === yearB;
  if (!hasSameYear) {
    return compareCondition(yearA, yearB);
  }
  //5. compare value
  if (periodValueA !== periodValueB) {
    // if period type is month then do sub sort by month
    if (periodTypeA === "Months") {
      return compareCondition(
        MONTHS.indexOf(periodValueA),
        MONTHS.indexOf(periodValueB)
      );
    }

    // if period type is month then do sub sort by month
    if (periodTypeA === "Seasons") {
      return compareCondition(
        SEASONS.indexOf(periodValueA),
        SEASONS.indexOf(periodValueB)
      );
    }

    if (periodTypeA === "Quarters") {
      return compareCondition(
        QUARTERS.indexOf(periodValueA),
        QUARTERS.indexOf(periodValueB)
      );
    }
  }

  //period values are the same
  let periodTypeACalendarMonth = getCalendarMonth(valueA);
  let periodTypeBCalendarMonth = getCalendarMonth(valueB);

  // compare year first
  if (periodTypeA === "Months") {
    return compareCondition(
      MONTHS.indexOf(periodTypeACalendarMonth),
      MONTHS.indexOf(periodTypeBCalendarMonth)
    );
  }
  // if its calendar with the same value compare the month
  if (periodTypeA === "Calendars") {
    if (periodTypeACalendarMonth == null) periodTypeACalendarMonth = "Dec";
    if (periodTypeBCalendarMonth == null) periodTypeBCalendarMonth = "Dec";
    return compareCondition(
      MONTHS.indexOf(periodTypeACalendarMonth),
      MONTHS.indexOf(periodTypeBCalendarMonth)
    );
  }

  if(yearA !== yearB){
    return compareCondition(yearA, yearB);
  }
  return defaultcomparator(valueA, valueB, nodeA, nodeB, isDescending);
};

export const oldTenorComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending
) => {
  valueA = valueA ?? "";
  valueB = valueB ?? "";

  let formattedValueA = valueA ?? "";
  let formattedValueB = valueB ?? "";

  const nodeAData = nodeA.group ? nodeA.aggData : nodeA.data;
  const nodeBData = nodeB.group ? nodeB.aggData : nodeB.data;
  //We only want to sort bu the main group rows.
  //This keeps the order of the sublegs and legs
  if (nodeAData.summary_id === nodeBData.summary_id) return 0;

  //if the summary rows has more than one value then we need to sort by the first value
  formattedValueA = valueA.split("/")[0].trim();
  formattedValueB = valueB.split("/")[0].trim();
  // remove the Month from calendars and seasons (Mar)
  const periodStringA = getTenorValueFromString(formattedValueA);
  const periodStringB = getTenorValueFromString(formattedValueB);

  // gets type if calendar, quarter, month, season
  const periodTypeA = getPeriodType(periodStringA);
  const periodTypeB = getPeriodType(periodStringB);
  // gets value without the year Mar-22 -> Mar
  const periodValueA = getPeriodTypeValue(periodStringA);
  const periodValueB = getPeriodTypeValue(periodStringB);

  if (periodTypeA === "error" || periodTypeB === "error") {
    return 0;
  }
  const periodTypeAPriortiy = PERIODS[periodTypeA]["priority"];
  const periodTypeBPriortiy = PERIODS[periodTypeB]["priority"];
  const hasSamePeriodType = periodTypeA === periodTypeB;
  /*
  1. compare period type
  -----
  2. compare number of legs
  3. compare option type
  ------
  4. compare year
  5. compare period value
  6. compare period value month -- for calendars
  */

  // 1. compare period type
  if (!hasSamePeriodType) {
    //compare period type
    return compareCondition(periodTypeAPriortiy, periodTypeBPriortiy);
  }

  // 2. compare chronologically
  let yearA = periodStringA.split("-")[1];
  let yearB = periodStringB.split("-")[1];
  const hasSameYear = yearA === yearB;
  if (!hasSameYear) {
    return compareCondition(yearA, yearB);
  }
  //5. compare value
  if (periodValueA !== periodValueB) {
    // if period type is month then do sub sort by month
    if (periodTypeA === "Months") {
      return compareCondition(
        MONTHS.indexOf(periodValueA),
        MONTHS.indexOf(periodValueB)
      );
    }

    // if period type is month then do sub sort by month
    if (periodTypeA === "Seasons") {
      return compareCondition(
        SEASONS.indexOf(periodValueA),
        SEASONS.indexOf(periodValueB)
      );
    }

    if (periodTypeA === "Quarters") {
      return compareCondition(
        QUARTERS.indexOf(periodValueA),
        QUARTERS.indexOf(periodValueB)
      );
    }
  }

  //period values are the same
  let periodTypeACalendarMonth = getCalendarMonth(valueA);
  let periodTypeBCalendarMonth = getCalendarMonth(valueB);

  // compare year first
  if (periodTypeA === "Months") {
    return compareCondition(
      MONTHS.indexOf(periodTypeACalendarMonth),
      MONTHS.indexOf(periodTypeBCalendarMonth)
    );
  }
  // if its calendar with the same value compare the month
  if (periodTypeA === "Calendars") {
    if (periodTypeACalendarMonth == null) periodTypeACalendarMonth = "Dec";
    if (periodTypeBCalendarMonth == null) periodTypeBCalendarMonth = "Dec";
    return compareCondition(
      MONTHS.indexOf(periodTypeACalendarMonth),
      MONTHS.indexOf(periodTypeBCalendarMonth)
    );
  }
  return compareCondition(yearA, yearB);
};

const isOptionGroup1 = (nodeData, optionType) => {
  const hasOneLeg =
    nodeData.row_type === 1 ||
    /// This is added for the team run sort -- this sort function is used both by the team run and the pricer
    //legs_count only exists in team run rows
    nodeData?.legs_count === 1;
  if (optionType) {
    const optionTypeLowerCase = optionType.toLowerCase();
    const isTypeStupid = optionTypeLowerCase.includes("stupid");
    const isTypeStraddle = optionTypeLowerCase.includes("straddle");
    const isTypeStrangle = optionTypeLowerCase.includes("strangle");
    /// is special or combination type
    const isSpecial = optionTypeLowerCase.slice(0,1) !== 'vs' && (optionTypeLowerCase.includes('vs') || 
    optionTypeLowerCase.includes('+'));
    return (isTypeStupid || isTypeStraddle || isTypeStrangle || hasOneLeg) && !isSpecial;
  }
  return hasOneLeg;
};

const getCalendarMonth = (value) => {
  let valueFormatted = value.split(" ");
  if (valueFormatted.length > 1) {
    return (valueFormatted = valueFormatted[1]
      .replace("(", "")
      .replace(")", ""));
  }
  return null;
};

export const defaultcomparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending
) => {
  // TODO:: this fires when clicking on custom filter buttons. WHY ?
  if (nodeA === undefined || nodeB === undefined) return 0;
  const nodeAData = nodeA.group ? nodeA.aggData : nodeA.data;
  const nodeBData = nodeB.group ? nodeB.aggData : nodeB.data;
  //We only want to sort bu the main group rows.
  //This keeps the order of the sublegs and legs
  if (nodeAData.summary_id === nodeBData.summary_id) return 0;
  return compareCondition(valueA, valueB);
}; 

export const summaryIdComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending
) => {
  // TODO:: this fires when clicking on custom filter buttons. WHY ?
  if (nodeA === undefined || nodeB === undefined) return 0;
  const nodeAData = nodeA.group ? nodeA.aggData : nodeA.data;
  const nodeBData = nodeB.group ? nodeB.aggData : nodeB.data;
  //We only want to sort bu the main group rows.
  //This keeps the order of the sublegs and legs
  if (nodeAData.summary_id === nodeBData.summary_id) return 0;
  return compareCondition(valueA, valueB);
};


export const typeComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
  // TODO:: This reutrns wrong values for valueB WHY ?
  // Values are getting numbers not type
  if (nodeA === undefined || nodeB === undefined) return 0;
  const nodeAData = nodeA.group ? nodeA.aggData : nodeA.data;
  const nodeBData = nodeB.group ? nodeB.aggData : nodeB.data;
  //We only want to sort bu the main group rows.
  //This keeps the order of the sublegs and legs
  if (nodeAData.summary_id === nodeBData.summary_id) return 0;

  


  const optionTypeA = nodeAData[FIELD_NAMES.TYPE];
  const optionTypeB = nodeBData[FIELD_NAMES.TYPE];
  const optionAGroup = isOptionGroup1(nodeAData, optionTypeA) ? "group1" : "group2";
  const optionBGroup = isOptionGroup1(nodeBData, optionTypeB) ? "group1" : "group2";


  //  compare grouping
  //------------------------------------ group 1
  // (all single legs,stupid,straddles,strangles)
  // ------------------------------------ group 2
  // (everything else)

  const hasSameOptionGroup = optionAGroup === optionBGroup;
  if (!hasSameOptionGroup) {
    //compare period type
    return compareCondition(GROUPS.indexOf(optionAGroup),GROUPS.indexOf(optionBGroup));
  }   
 
  return 0; 
};
export const strikeComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending
) => {
  valueA = valueA ?? "";
  valueB = valueB ?? "";

  let formattedValueA = valueA ?? "";
  let formattedValueB = valueB ?? "";

  const nodeAData = nodeA.group ? nodeA.aggData : nodeA.data;
  const nodeBData = nodeB.group ? nodeB.aggData : nodeB.data;
  
  //We only want to sort bu the main group rows.
  //This keeps the order of the sublegs and legs
  if (nodeAData.summary_id === nodeBData.summary_id) return 0;

  // if strike is a string it gets the first number in that string.
  formattedValueA = getStrikeValueFromString(valueA);
  formattedValueB = getStrikeValueFromString(valueB);

  if(formattedValueA !== formattedValueB){
    return compareCondition(formattedValueA, formattedValueB);

  }
     
  return defaultcomparator(valueA, valueB, nodeA, nodeB, isDescending);
};

const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SEASONS = ["Sum", "Win"];

const CALENDARS = ["Cal-1"];

const GROUPS = ["group1", "group2"];

const PERIODS = {
  Months: {
    name: "Months",
    priority: 1,
    values: MONTHS,
  },
  Quarters: {
    name: "Quarters",
    priority: 2,
    values: QUARTERS,
  },
  Seasons: {
    name: "Seasons",
    priority: 3,
    values: SEASONS,
  },
  Calendars: {
    name: "Calendars",
    priority: 4, 
    values: CALENDARS,
  },
};
