import  { useContext } from "react";
import _ from 'lodash';
import { useQuery } from "@tanstack/react-query";
import api from "api/api";
import { TeamRunReadonlyContext } from "contexts/TeamRunContextReadonly";

export const prepareBody = (response) => {
  if(_.isEmpty(response)) return;
  let sortedBody = {};
  Object.keys(response).forEach(item => {
    const { title, rows } =response[item];
    sortedBody = {
      ...sortedBody,
      [title] : rows
    }
  });
  return sortedBody;
};
const useTeamRunReadonly = () => {
  const { setTeamRunData } = useContext(TeamRunReadonlyContext);

  const { isFetching } = useQuery(
    ["team-run-read-only"],
    async () =>
      await api
        .get("team-run/read-only")
        .then((res) => res?.data?.data)
        .catch((err) => console.error({ err })),
    {
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,
      onSuccess: (successItem) => {
        const res = prepareBody(successItem);
        setTeamRunData(res);
      },
    }
  );

  return { isFetching };
};

export default useTeamRunReadonly;
