import axios from "./axios";

 const api =  () => {
  return {
    get: (url, params, data) => {
      return axios.get(url, { params, data });
    },
    post: (url, params, data) => {
      return axios.post(url, data , { params });
    },
    put: (url, params, data) => {
      return axios.put(url, data, { params });
    },
    delete: (url, params, data) => {
      return axios.delete(url, { params, data });
    },
  };
};


export default api()