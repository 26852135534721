import VolSurfaceContainer from 'components/VolSurface/VolSurfaceContainer';
import VolSurfacePageLayout from 'components/VolSurface/VolSurfaceLayout/VolSurfaceLayout';
import VolSurfaceState from 'contexts/VolSurfaceContext';
import { VolSurfaceControlTableProvider } from 'contexts/VolSurfaceControlTableContext';
import React from 'react'

export default function VolSurfaceData() {
  return (
    <VolSurfacePageLayout>
      <VolSurfaceState>
        <VolSurfaceControlTableProvider>
          <VolSurfaceContainer />
        </VolSurfaceControlTableProvider>
      </VolSurfaceState>
    </VolSurfacePageLayout>
  );
}
