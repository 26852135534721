import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { getRowClass, getRowStyle } from "constant/AgGridCommonStyle";
import { VOL_SURFACE_STICKY_STRIKE_COLS } from "constant/VolSurface";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { cellClickedListener, getRowId } from "helpers/generalHelper";
import useUpdateStrikeTable from "./hooks/useFetchStrikeTable";
import { onGridReady } from "helpers/volSurfaceHelper";

function SingleStrikeTable() {
  const [toNextCell, setToNextCell] = useState(false);
  const { setSelectedRows, stickyStrikeData } = useVolSurfaceControlTable();
  const columnDefs = useMemo(() => VOL_SURFACE_STICKY_STRIKE_COLS, []);

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const gridRef = useRef();

  const { onCellEditingStopped } = useUpdateStrikeTable({
    gridRef: gridRef,
    toNextCell: toNextCell,
  });

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
    animateRows: true,
  };

  const refreshTable = () => {
    let refreshParams = { force: true };
    gridRef.current.api.refreshCells(refreshParams);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        setToNextCell(true);
      }else if (event.key === "Escape") {
        setToNextCell(false);
      }
    };

    const handleMouseDown = () => {
      setToNextCell(false);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleMouseDown);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedRows(selectedRows);
    refreshTable();
  };

  return (
    <AgGridReact
      getRowId={getRowId}
      onCellEditingStopped={onCellEditingStopped}
      gridOptions={gridOptions}
      onGridReady={onGridReady}
      headerHeight={30}
      defaultColDef={{ suppressMenu: true }}
      rowHeight={30}
      rowData={stickyStrikeData}
      onSelectionChanged={onSelectionChanged}
      
      getRowStyle={(params) => {
        if (params.data.field !== "strike") {
          return {
            borderTop: "none",
            borderBottom: "none",
          };
        }
        getRowStyle(params);
      }}
      getRowClass={getRowClass}
      ref={gridRef}
      onCellValueChanged={(event) => {
        if (event.source === "paste") {
          onCellEditingStopped(event);
        }
      }}
      stopEditingWhenCellsLoseFocus={true}
      suppressMovableColumns={true}
      columnDefs={columnDefs}
      groupDefaultExpanded={1}
      suppressContextMenu
      checkboxSelection
      rowSelection={"multiple"}
      onCellClicked={(e) => {
        cellClickedListener(e);
        refreshTable();
      }}
      suppressRowClickSelection
      className={`ag-theme-alpine pricer-ag-grid__table ${
        dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
      }`}
    />
  );
}

export default SingleStrikeTable;
