import { useCallback, useContext } from "react";
import { getGraphsData, updateOnEditCell } from "api/volSurfaceApi";
import { VolSurfaceContext } from "contexts/VolSurfaceContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { revertCellValue, setDataToLocalStorage } from "helpers/generalHelper";
import {
  updateGraph,
  updateVolSurfaceBodyData,
  updateVolSurfaceData,
  updateVolSurfaceEstimateData,
} from "helpers/volSurfaceHelper";
import { SCROLL_POSITION } from "constant/VolSurface";

function useUpdateControlTable({ scrollableRef, gridRef }) {
  const {
    setIsCellEditing,
    setVolSurfaceData: setRowsData,
    setRowEditingData,
    selectedFilters,
    volSurfaceTablesData: rowsData,
    setVolCalcTable,
    volCalcTable,
    setScrollPosition,
  } = useVolSurfaceControlTable();

  const {
    state: { volSurfaceData },
    setVolSurfaceData,
  } = useContext(VolSurfaceContext);

  const onCellEditingStopped = useCallback(
    (event) => {
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      if (+event.oldValue === +event.newValue) return;

      const { prepareBody, bodyForUnderlying } = updateVolSurfaceBodyData(
        event,
        selectedFilters
      );
      if (!selectedFilters?.comparison_surface_id) {
        delete prepareBody.comparison_surface_id;
        delete bodyForUnderlying.comparison_surface_id;
      }
      setIsCellEditing(true);
      setScrollPosition(scrollableRef.current.scrollTop);
      setDataToLocalStorage(SCROLL_POSITION, scrollableRef.current.scrollTop);
      updateOnEditCell(
        event.colDef.headerName === "Tenor" ? bodyForUnderlying : prepareBody
      )
        .then((res) => {
          if (res.status !== 200) {
            revertCellValue(event.node, event.oldValue);
          } else {
            const updatedData = updateVolSurfaceData(
              res.data.data.control_table_rows,
              rowsData
            );
            setRowsData(updatedData);
            if (res.data.data.graphs_data) {
              updateGraph(res, volSurfaceData);
              setVolSurfaceData(volSurfaceData);
            }
            const volCalcTableUpdatedData = updateVolSurfaceEstimateData(
              res.data.data.vol_calc_table_rows,
              volCalcTable
            );
            setVolCalcTable(volCalcTableUpdatedData);

            let refreshParams = { force: true };
            gridRef.current.api.refreshCells(refreshParams);
            setIsCellEditing(false);
            setRowEditingData(null);
            if (event.colDef.headerName === "Tenor") {
              getGraphsData({
                product_contract_style_id: selectedFilters?.product_id,
                risk_free: selectedFilters?.risk_free || 0,
                comparison_surface_id: selectedFilters?.comparison_surface_id,
              }).then((res) => {
                const response = res.data.data;
                setVolSurfaceData(response);
              });
            }
          }
        })
        .catch((e) => {
          revertCellValue(event.node, event.oldValue);

          console.log("Error while setting data");
          setIsCellEditing(false);
          setRowEditingData(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rowsData,
      selectedFilters,
      setIsCellEditing,
      setRowEditingData,
      volSurfaceData,
    ]
  );
  
  return { onCellEditingStopped };
}

export default useUpdateControlTable;
