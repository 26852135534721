import { updateOnEditEstimateCell } from "api/volSurfaceApi";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { revertCellValue } from "helpers/generalHelper";
import { updateVolSurfaceEstimateData } from "helpers/volSurfaceHelper";
import { useCallback } from "react";

function useUpdateEstimatesTable({ gridRef }) {
    const {
      setIsCellEditing,
      setRowEditingData,
      setVolCalcTable,
      volCalcTable,
    } = useVolSurfaceControlTable();


  const onCellEditingStopped = useCallback(
    (event) => {
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      if (+event.oldValue === +event.newValue) return;

      const node = event.node;
      const rowData = node.group ? node.aggData : node.data;

      const prepareBody = {
        vol_smile_id: rowData.vol_smile_id,
        new_strike: +event.newValue,
        column_key: event?.colDef?.field === "0" ? 0 : 1,
      };

      setIsCellEditing(true);
      updateOnEditEstimateCell(prepareBody)
        .then((res) => {
          if (res.status !== 200) {
            revertCellValue(event.node, event.oldValue);
          } else {
            const updatedData = updateVolSurfaceEstimateData(
              res.data.data,
              volCalcTable
            );
            setVolCalcTable(updatedData);
            let refreshParams = { force: true };
            gridRef.current.api.refreshCells(refreshParams);
            setIsCellEditing(false);
            setRowEditingData(null);
          }
        })
        .catch((e) => {
          revertCellValue(event.node, event.oldValue);
          console.log("Error while setting data");

          setIsCellEditing(false);
          setRowEditingData(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsCellEditing, setRowEditingData, setVolCalcTable, volCalcTable]
  );

   return { onCellEditingStopped }
}

export default useUpdateEstimatesTable
