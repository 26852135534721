/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import _ from "lodash";
import { useNavigate, generatePath } from "react-router-dom";
import { Dropdown, Menu, Space } from "antd";
import { ROUTES_PATH } from "routes";
// Icons
import { MenuOutlined } from "@ant-design/icons";
// Helpers

// Context
import { TeamRunReadonlyContext } from "contexts/TeamRunContextReadonly";
// Style
import { GlobalContext } from "contexts/GlobalContext";
import { extractCopyShortHandForSummaryRows } from "helpers/generalHelper";

import { ACTION_BUTTONS_NAME } from "constant/GeneralActionButtons";
import {
  ACTION_COLUMN_MENU_ITEMS,
  TEAM_RUN_READONLY_COLORS_STATE,
} from "constant/TeamRunReadonly/TeamRunReadonly";
import { TEAMRUN_DATAfun } from "helpers/teamRunReadonlyHelper";

const ActionColumn = (props) => {
  const authorizedActions = [
    ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key,
  ];

  const navigate = useNavigate();
  const {
    state: { selectedRows, teamRunData },
    setAdditionalPopupData,
  } = useContext(TeamRunReadonlyContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const [menuItemsClicked, setMenuItemsClicked] = useState("");
  const [menuItemsData, setMenuItemsData] = useState(ACTION_COLUMN_MENU_ITEMS);
  const [rowData, setRowData] = useState({});

  const colorArray = TEAMRUN_DATAfun()?.[TEAM_RUN_READONLY_COLORS_STATE];

  useEffect(() => {
    if (_.isEmpty(props.data)) return;

    setRowData(props.data);
  }, [props.data]);

  const selectedRowsLength = useMemo(
    () =>
      props.data &&
      selectedRows &&
      selectedRows[props.data.product_title]?.length > 1,
    [props.data, selectedRows, teamRunData]
  );

  useEffect(() => {
    setMenuItemsData(
      selectedRowsLength
        ? [
            ...returnSelectedDropDown().filter(
              (item) =>
                item.key === "copy_no_vol" || item.key === "copy_shorthand"
            ),
          ]
        : menuItemsData
    );
  }, [selectedRowsLength, props.data, teamRunData]);

  useEffect(() => {
    if (teamRunData) {
      let updatedRowData = teamRunData[props.data.product_title]?.find(
        (row) => row.id === props.data.id
      );
      setRowData(updatedRowData);
    }
  }, [teamRunData, props.data]);

  const handleMenuItemClick = useCallback(
    async (itemClicked) => {
      if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key) {
        extractCopyShortHandForSummaryRows(props);
      }

      if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key) {
        extractCopyShortHandForSummaryRows(props, false);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key) {
        const preparePath = generatePath(ROUTES_PATH.AUDIT_TRAIL.auditDetails, {
          id: rowData.id,
        });
        navigate(preparePath);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key) {
        let teamRun_data = {
          isAdditionalDataItemClicked: true,
          isOpen: true,
          data: props?.data ? props.data : props.node.aggData,
        };

        setAdditionalPopupData(teamRun_data);
        return;
      }
      setTimeout(() => setMenuItemsClicked(""), 0);
    },
    [rowData, selectedRows, colorArray, selectedRowsLength]
  );

  const returnSelectedDropDown = () => {
    if (rowData) {
      return menuItemsData;
    }
    return [];
  };

  const showDropDown = () => {
    return (
      <Dropdown
        className={`action-buttons__dropdown button-icon ${
          !dark_Theme && "button-light-theme"
        }`}
        trigger={["click"]}
        overlay={
          <Menu
            onClick={({ key }) => handleMenuItemClick(key)}
            key="action-buttons__menu-items_MENU"
            items={
              !menuItemsClicked && !selectedRowsLength
                ? returnSelectedDropDown()
                    ?.sort((a, b) => a.index - b.index)
                    .map((item) =>
                      authorizedActions.includes(item.key) ? item : null
                    )
                : menuItemsData
                    ?.sort((a, b) => a.index - b.index)
                    .map((item) =>
                      authorizedActions.includes(item.key) ? item : null
                    )
            }
            theme={dark_Theme ? "dark" : "light"}
          />
        }
        overlayClassName={dark_Theme && "menu-dropdown-dark"}
      >
        <Space>
          <MenuOutlined />
        </Space>
      </Dropdown>
    );
  };

  return (
    <div className="action-buttons__container action-button_container justify-content-start">
      {/* Show Menu Item When Menu Icon Clicked */}
      {showDropDown()}
    </div>
  );
};

export default ActionColumn;
