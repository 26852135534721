/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
import {
  commonStyle,
  headerHeight,
  rowHeight,
} from "constant/AgGridCommonStyle";
import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_FONT_COLOR_DARK,
  DEFAULT_FONT_COLOR_LIGHT,
  TRADER_COLUMNS,
} from "constant/Trader/Trader";
import { GlobalContext } from "contexts/GlobalContext";
import {
  addingBordersForCarbon,
  addingBordersForOptionsExceptCarbon,
  getSvgPath_d,
} from "helpers/generalHelper";
import { useCallback, useContext } from "react";

function SingleTraderTable({
  item,
  teamRunData,
  onGridReady,
  onColumnResized,
  handleRowsSelection,
  gridRef,
  productIds,
  handleNotifyTeamRunRowsViewed,
}) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const getRowId = (params) => params.data.id;

  const cellClickedListener = useCallback(
    (e, productName) => {
      const field = e.colDef.field;
      // ------- handling notify team run rows viewed -------
      const checkMenu = getSvgPath_d(e);

      const itemObject = e.event.target.dataset;

      // const item =  itemObjec
      if (field === "title") {
        handleNotifyTeamRunRowsViewed(productName);
      }
      // ------- handling notify team run rows viewed -------
      const item =
        Object.keys(itemObject).length > 0
          ? itemObject.icon
          : checkMenu
          ? "menu"
          : undefined;
      const isbulkMenuPressed = field === "actions" && item === "menu";
      const isSelected = e.node.isSelected();
      //deselects all nodes.
      const deselectAll = () => e?.api?.deselectAll();
      if (e.node.level > 0) {
        deselectAll();
        const parent = e.node.parent;
        let children = parent.allLeafChildren;
        if (parent !== "undefined" && parent.group && parent.level !== 0)
          children = parent.parent.allLeafChildren;
        children?.forEach((node) => node.setSelected(true));
        return;
      }
      /*
      this is for summary rows.
      if pressed on action menu just add the row to selected
      if not action menu remove selection on other rows and set it selected
    */
      if (!(isbulkMenuPressed && isSelected)) deselectAll();
      e.node.setSelected(true);
    },
    [productIds, teamRunData]
  );

  // Function to get row style based on params and theme
  const getRowStyle = (params) => {
    const { data, node } = params;
    const isSelected = node.selected;
    const fontColor = data.font_color;

    // Base style object
    let style = {
      color:
        fontColor ||
        (dark_Theme ? DEFAULT_FONT_COLOR_DARK : DEFAULT_FONT_COLOR_LIGHT),
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    };

    // Modify styles if the row is selected or dark theme is active
    if (isSelected) {
      style.color = fontColor || DEFAULT_FONT_COLOR_DARK;
    } else if (dark_Theme) {
      style.backgroundColor = commonStyle.backgroundColor;
    }

    return style;
  };

  return (
    <AgGridReact
      onRowDataUpdated={(event) => {
        if (item === "Carbon") {
          addingBordersForCarbon(event, item);
        } else {
          addingBordersForOptionsExceptCarbon(event);
        }
      }}
      getRowId={getRowId}
      ref={(ref) => (gridRef.current[item] = ref)}
      onGridReady={(params) => onGridReady(params, item)}
      className={`ag-theme-alpine  ${
        dark_Theme ? " ag-theme-balham-dark" : " ag-theme-balham"
      }`}
      columnDefs={TRADER_COLUMNS(item)}
      rowData={teamRunData[item]}
      defaultColDef={{
        editable: false,
        resizable: true,
        filter: false,
      }}
      headerHeight={headerHeight}
      getContextMenuItems={[]}
      getRowStyle={getRowStyle}
      columnHoverHighlight={false}
      getRowHeight={rowHeight}
      onCellClicked={(e) => cellClickedListener(e, item)}
      // row selection
      multiSortKey="ctrl" // for multi sort
      checkboxSelection
      rowSelection={"multiple"}
      suppressRowClickSelection
      animateRows
      rowDragManaged
      suppressRowHoverHighlight
      suppressContextMenu
      onSelectionChanged={(params) => handleRowsSelection(params, item)}
      suppressMovableColumns={true}
      onColumnResized={(params) => onColumnResized(params, item)}
    />
  );
}

export default SingleTraderTable;
