/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";

// Helpers
import {
  TEAM_RUN_READONLY_COULMN_STATE,
  TEAM_RUN_READONLY_DRAGEABLE_STATE,
  TEAMRUN_READONLY_STATE,
} from "constant/TeamRunReadonly/TeamRunReadonly";
// API
import { onTableClick, triggerSingleAPI } from "api/teamRunApi";
// Hooks
import useTeamRunReadonly, { prepareBody } from "hooks/useTeamRunReadonly";
import useWebSocket from "hooks/useWebSocket";

// Style
import "styles/index.css";
import "./TeamRunReadonlyAgGridTables.scss";
import {
  getDataFromLocalStorage,
  updateTeamRunData,
} from "helpers/generalHelper";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import SingleAgGridTable from "./SingleAgGridTable";
import {
  onTeamRunColumnResized,
  onTeamRunDragAndDrop,
  onTeamRunRowsSelection,
  onTeamTablesReady,
  setSelectedRowsForTeamRun,
  TEAMRUN_DATAfun,
} from "helpers/teamRunReadonlyHelper";
import { TeamRunReadonlyContext } from "contexts/TeamRunContextReadonly";

const TeamRunReadOnlyAgGridTables = () => {
  const gridRef = useRef({});

  const [columnState, setColumnState] = useState(
    TEAMRUN_DATAfun()?.[TEAM_RUN_READONLY_COULMN_STATE]
  );

  const location = useLocation();
  //!Call API To Fetch Tables Data
  useTeamRunReadonly();

  const {
    state: {
      teamRunData,
      productIds,
    },
    setTeamrunRefAgGrid,
    setTeamRunData,
    setSelectedRows,
  } = useContext(TeamRunReadonlyContext);

  const { data, title, product_id } = useWebSocket(teamRunData);
  const [checkSelection, setCheckSelection] = useState(true);

  // this is for dragable team run products in home page
  const [teamRunDataState, setTeamRunDataState] = useState(
    Object.keys(teamRunData || {})
  );

  const [isResizingColumn, setIsResizingColumn] = useState(false);

  useEffect(() => {
    let dataFromLocalStorage = getDataFromLocalStorage(
      TEAMRUN_READONLY_STATE
    )?.[TEAM_RUN_READONLY_DRAGEABLE_STATE];
    if (dataFromLocalStorage) {
      setTeamRunDataState(dataFromLocalStorage);
    } else {
      setTeamRunDataState(Object.keys(teamRunData || {}));
    }
  }, [teamRunData]);

  useEffect(() => {
    // MouseOver event listener
    const handleMouseOver = (event) => {
      const isMouseOverHeader = event.target.closest(".ag-header-cell-resize");
      if (isMouseOverHeader) {
        setIsResizingColumn(true);
      } else {
        setIsResizingColumn(false);
      }
    };

    document.addEventListener("mouseover", handleMouseOver);
    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  });




  useEffect(() => {
    if (!gridRef.current) return;
    setTeamrunRefAgGrid(gridRef.current);
  }, [gridRef.current]);

  useEffect(() => {
    if (checkSelection) {
      setSelectedRowsForTeamRun(gridRef);
    }
  }, [checkSelection]);

  useEffect(() => {
    const onTeamRunProductSuccess = (data, productId, title) => {
      if (gridRef.current[title]) {
        let updatedData = updateTeamRunData(title, data, gridRef.current);
        setTeamRunData({ ...teamRunData, [title]: updatedData });
      } else {
        if (!productIds || !productId) return;
        const findProductName = productIds.find(
          (item) => item.id === productId
        )?.name;
        const prepareData = {
          ...teamRunData,
          [findProductName]: data,
        };
        setTeamRunData(prepareData);
      }
    };
    onTeamRunProductSuccess(data, product_id, title);
  }, [data, product_id, productIds, title, gridRef.current]);

  gridRef?.current.api?.refreshCells({ force: true });
  gridRef?.current.api?.redrawRows();

  const onTableClickHandler = (productName, classNameClicked) => {
    if (classNameClicked !== "ag-center-cols-viewport") return;
    handleNotifyTeamRunRowsViewed(productName);
  };

  function triggerSingleApiFun(findProductId) {
    triggerSingleAPI(findProductId)
      .then(({ data }) => {
        const formattedData = prepareBody(data);
        setTeamRunData(formattedData);
      })
      .catch((err) => console.log({ err }));
  }

  /// to handle saving that the rows are viewed
  const handleNotifyTeamRunRowsViewed = (productName) => {
    if (!productIds || !productName) return;
    const findProductId = productIds?.find(
      (productItem) => productItem.name === productName
    )?.id;
    const isTeamRunIsNewPropertyTrue = teamRunData[productName]?.some(
      (item) => !!item.is_new
    );
    if (!isTeamRunIsNewPropertyTrue) return;

    onTableClick(findProductId).then(() => {
      triggerSingleApiFun(findProductId);
    });
  };

  const teamRunProducts = () => {
    if (location.pathname.includes("team-run")) {
      return Object.keys(teamRunData || {});
    } else {
      return teamRunDataState;
    }
  };

  return (
    <DragDropContext
      onDragEnd={(result) =>
        onTeamRunDragAndDrop(result, teamRunDataState, setTeamRunDataState)
      }
    >
      <Droppable
        droppableId="root-team-run-readonly"
        type="group"
        direction="horizontal"
      >
        {(provided) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="dropable-container"
            >
              <div className="ag-grid-dynamic-table__container">
                {[...teamRunProducts()].map((item, index) => {
                  return (
                    <Draggable
                      draggableId={item}
                      key={item}
                      index={index}
                      ref={provided.innerRef}
                      shouldRespectForcePress={true}
                      disableInteractiveElementBlocking={true}
                      isDragDisabled={
                        location.pathname.includes("team-run") ||
                        isResizingColumn
                      }
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            {...snapshot}
                            ref={provided?.innerRef}
                            className="table-box"
                            onClick={(event) => {
                              onTableClickHandler(item, event.target.className);
                            }}
                            isDragDisabled={
                              location.pathname.includes("team-run") ||
                              isResizingColumn
                            }
                            isDragging={snapshot.isDragging}
                          >
                            <SingleAgGridTable
                              gridRef={gridRef}
                              handleRowsSelection={(params, productName) =>
                                onTeamRunRowsSelection(
                                  params,
                                  productName,
                                  setSelectedRows
                                )
                              }
                              item={item}
                              teamRunData={teamRunData}
                              key={item}
                              onColumnResized={(params, productName) =>
                                onTeamRunColumnResized(
                                  params,
                                  productName,
                                  setColumnState
                                )
                              }
                              onGridReady={(params, productName) =>
                                onTeamTablesReady(
                                  params,
                                  productName,
                                  columnState,
                                  teamRunData,
                                  setCheckSelection
                                )
                              }
                              productIds={productIds}
                              handleNotifyTeamRunRowsViewed={
                                handleNotifyTeamRunRowsViewed
                              }
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </div>
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default TeamRunReadOnlyAgGridTables;
