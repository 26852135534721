import React, { useState, useContext } from "react";
// Icons
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
// Helpers

// Style
import { GlobalContext } from "contexts/GlobalContext";
import classnames from "classnames";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { toast } from "helpers/generalHelper";
import {
  addEmptyStrikeRow,
  deleteRow,
  getVolSurfaceStickyStrikes,
} from "api/volSurfaceApi";
import { Modal, Spin, Tooltip } from "antd";
import { createPortal } from "react-dom";

const ActionColumn = (props) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const { selectedRows, selectedFilters, setStickyStrikeData, setSurfaceTime } =
    useVolSurfaceControlTable();

  function onDeleteClick() {
    const extractSummaryIds = selectedRows?.length
      ? selectedRows.map((item) => {
          return {
            vol_smile_id: item.vol_smile_id,
            key: item.key,
          };
        })
      : [
          {
            vol_smile_id: props.data.vol_smile_id,
            key: props.data.key,
          },
        ];
    
    setConfirmLoading(true);
    deleteRow(extractSummaryIds)
      .then((res) => {
        setIsLoading(true);
        fetchTableData();
        toast(res?.data?.message, "success");
        setConfirmLoading(false);
        setShowModal(false);
      })
      .catch((err) => {
        setConfirmLoading(false);
        setShowModal(false);
        console.log("Error >>", err);
      });
  }

  const fetchTableData = () => {
    getVolSurfaceStickyStrikes({
      product_contract_style_id: selectedFilters?.product_id,
      date_time: selectedFilters?.strike_date,
    })
      .then((res) => {
        setStickyStrikeData(res.data.data?.table);
        setSurfaceTime(res.data.data?.time);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleAddRow = (isAbove) => {
    setIsLoading(true);
    addEmptyStrikeRow({
      vol_smile_id: props.data.vol_smile_id,
      key: props.data.key,
      is_above: isAbove,
    })
      .then((_) => {
        fetchTableData();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error >>", err);
      });
  };

  return (
    <div>
      {isLoading ? (
        <Spin size="small" className="mx-4" />
      ) : (
        <>
          <DeleteOutlined
            disabled={isLoading}
            role="button"
            onClick={() => {
              setShowModal(true);
            }}
            className={classnames("button-icon fs-6 text-danger", {
              "button-light-theme": !dark_Theme,
            })}
          />
          <Tooltip title="add row above">
            <ArrowUpOutlined
              disabled={isLoading}
              onClick={() => {
                handleAddRow(1);
              }}
              role="button"
              className={classnames("button-icon mx-2 ", {
                "button-light-theme": !dark_Theme,
              })}
            />
          </Tooltip>
          <Tooltip title="add row below">
            <ArrowDownOutlined
              disabled={isLoading}
              onClick={() => {
                handleAddRow(0);
              }}
              role="button"
              className={classnames("button-icon ", {
                "button-light-theme": !dark_Theme,
              })}
            />
          </Tooltip>
        </>
      )}

      {createPortal(
        <Modal
          className={`${dark_Theme && "ant-modal-dark content_dark"}`}
          title="Delete Confirmation"
          style={{
            top: 20,
          }}
          confirmLoading={confirmLoading}
          open={showModal}
          okText="Delete"
          onOk={() => onDeleteClick()}
          onCancel={() => setShowModal(false)}
          centered
        >
          <p>
            Are you sure you want to delete this{" "}
            {selectedRows?.length === 0 ? "option" : "options"}? This action
            cannot be undone.
          </p>
        </Modal>,
        document.body
      )}
    </div>
  );
};

export default ActionColumn;
