import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "components/Layout";
import * as Sentry from "@sentry/react";
import { GEEKS_USER } from "constant/Profile/Profile";
import { getDataFromLocalStorage } from "helpers/generalHelper";
import VolSurfaceData from "pages/VolSurfaceData/VolSurfaceData";
import NotFound from "pages/NotFound/NotFound";
import Trader from "pages/Trader/Trader";
import { NAVBAR_NAMES } from "constant/Navbar";
import { FULL_ROUTES_PATH } from "constant/Routes";
import TeamRunReadonly from "pages/TeamRunReadonly/TeamRunReadonly";
const AuditTrail = lazy(() => import("pages/AuditTrail/AuditTrail"));
const VolSurface = lazy(() => import("pages/VolSurface/VolSurface"));
const TradeConfirm = lazy(() => import("pages/TradeConfirm/TradeConfirm"));
const MarketAnalysis = lazy(() =>
  import("pages/MarketAnalysis/MarketAnalysis")
);

const AuthRoute = lazy(() => import("components/AuthRoute"));
// const PricerPage = lazy(() =>
//   import("../features/pricer_page/components/PricerGridComponent")
// );
const Home = lazy(() => import("pages/Home/Home"));
// const NewPricer = lazy(() => import("pages/Pricer/Pricer"));
const LoginPage = lazy(() => import("pages/LoginPage/LoginPage"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgetPassword = lazy(() => import("pages/ForgetPassword"));
// const TeamRun = lazy(() => import("pages/TeamRun"));
const Profile = lazy(() => import("pages/Profile"));

/**
 *  Used for share paths in component to prevent write or redirect path static in code
 */

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export const RenderRoutes = ({ routes }) => {
  const pages = getDataFromLocalStorage(GEEKS_USER)?.authorized_pages;
  const authorizedRoutes = pages
    ? routes.filter((route) => {
        return pages?.includes(route.key);
      })
    : routes;

  if (authorizedRoutes && authorizedRoutes.length > 0) {
    if (authorizedRoutes.at(0).key === "vol-surface-data") {
      authorizedRoutes.at(0).path = FULL_ROUTES_PATH.VOL_SURFACE_Data.index;
    } else {
      authorizedRoutes.at(0).path = "/";
    }
  }

  authorizedRoutes.push({
    path: FULL_ROUTES_PATH.AUDIT_TRAIL.auditDetails,
    key: "audit-trail-details",
    auth: true,
    component: <AuditTrail key={"audit-trail-details"} />,
  });

  function onClickHandle() {
    if (authorizedRoutes.at(0).key === "vol-surface-data") {
      window.location.href = FULL_ROUTES_PATH.VOL_SURFACE_Data.index;
    } else {
      window.location.href = "/";
    }
  }

  return (
    <SentryRoutes>
      <Route
        key={FULL_ROUTES_PATH.TRADE_CONFIRM.index}
        path={FULL_ROUTES_PATH.TRADE_CONFIRM.index}
        element={<TradeConfirm />}
      />
      {authorizedRoutes.map((route) => {
        return route.auth ? (
          <Route
            key={route.key}
            path={route.path}
            element={
              <AuthRoute>
                <Layout navTabs={authorizedRoutes}>{route.component}</Layout>
              </AuthRoute>
            }
          />
        ) : (
          <Route key={route.key} path={route.path} element={route.component} />
        );
      })}
      <Route path="*" element={<NotFound onClick={onClickHandle} />} />
    </SentryRoutes>
  );
};

const ROUTES = [
  {
    path: FULL_ROUTES_PATH.HOME.index,
    key: "home",
    auth: true,
    component: <Home />,
    label: NAVBAR_NAMES.HOME,
  },

  {
    path: FULL_ROUTES_PATH.SIGN_IN.index,
    key: "sign_in",
    component: <LoginPage />,
  },
  {
    path: FULL_ROUTES_PATH.RESET_PASSWORD.ResetPassword,
    key: "reset_password",
    component: <ResetPassword />,
  },
  {
    path: FULL_ROUTES_PATH.FORGET_PASSWORD.forgetPassword,
    key: "forget_password",
    component: <ForgetPassword />,
  },
  {
    path: FULL_ROUTES_PATH.TRADER.index,
    key: "trader",
    auth: true,
    label: NAVBAR_NAMES.TRADER,
    component: <Trader />,
  },
  {
    path: FULL_ROUTES_PATH.TEAM_RUN_READONLY.index,
    key: "team-run-read-only",
    auth: true,
    label: NAVBAR_NAMES.TEAM_RUN_READONLY,
    component: <TeamRunReadonly />,
  },
  {
    path: FULL_ROUTES_PATH.AUDIT_TRAIL.index,
    key: "audit-trail",
    auth: true,
    label: NAVBAR_NAMES.AUDIT_TRAIL,
    component: <AuditTrail />,
  },
  {
    path: FULL_ROUTES_PATH.AUDIT_TRAIL.auditDetails,
    key: "audit-trail-details",
    auth: true,
    component: <AuditTrail key={"audit-trail-details"} />,
  },

  // {
  //   path: FULL_ROUTES_PATH.PRICER.index,
  //   key: "pricer",
  //   auth: true,
  //   component: <NewPricer />,
  // label: NAVBAR_NAMES.PRICER
  // },
  // {
  //   path: FULL_ROUTES_PATH.TEAM_RUN.index,
  //   key: "team-run",
  //   auth: true,
  // label: NAVBAR_NAMES.TEAM_RUN
  //   component: <TeamRun />,
  // },
  {
    path: FULL_ROUTES_PATH.VOL_SURFACE.index,
    key: "vol-surface",
    auth: true,
    label: NAVBAR_NAMES.VOL_SURFACE,
    component: <VolSurface />,
  },

  {
    path: FULL_ROUTES_PATH.MARKET_ANALYSIS.index,
    key: "market-analysis",
    auth: true,
    label: NAVBAR_NAMES.MARKET_ANALYSIS,
    component: <MarketAnalysis />,
  },
  {
    path: FULL_ROUTES_PATH.VOL_SURFACE_Data.index,
    key: "vol-surface-data",
    auth: true,
    component: <VolSurfaceData />,
    label: NAVBAR_NAMES.VOL_SURFACE_DATA,
  },
  {
    path: FULL_ROUTES_PATH.PROFILE.index,
    key: "profile",
    auth: true,
    component: <Profile />,
  },
  {
    path: FULL_ROUTES_PATH.TRADE_CONFIRM.index,
    key: "trade-confirm",
    component: <TradeConfirm />,
  },
];

export const ROUTES_PATH = FULL_ROUTES_PATH;

export default ROUTES;
