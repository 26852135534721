import React, { useContext } from "react";
// Icons
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
// API
import { updateLock } from "api/pricerApi";
// Lib Helpers
import { PricerContext } from "contexts/PricerContext";
import { isLegLevel } from "helpers/Pricer/Table";
import { FIELD_NAMES } from "constant/Pricer/PricerTable";
// Style
import "./LockIcon.scss";

const LockIcon = (props) => {
  const {
    state: { pricerTablesData },
    setIsCellLocking,
    setPricerData,
    setCellLockingSummaryId,
  } = useContext(PricerContext);
  const rowData = props.node.group ? props.node.aggData : props.node.data;

  const isLocked = Boolean(rowData?.[FIELD_NAMES.IS_LOCKED]);

  if (!isLegLevel(props)) return <></>;

  const onIconClick = () => {
    const isAggData = props.node.group ? props.node.aggData : props.node.data;
    setCellLockingSummaryId(isAggData?.[FIELD_NAMES.SUMMARY_ID]);
    setIsCellLocking(true);
    const prepareBody = {
      [FIELD_NAMES.OPTION_ROW_ID]: isAggData?.id,
      [FIELD_NAMES.SUMMARY_ID]: isAggData?.[FIELD_NAMES.SUMMARY_ID],
      [FIELD_NAMES.IS_LOCKED]: 0, //! Should Send When We Wanna UnLock Only
      [FIELD_NAMES.LEG_ID]: +isAggData?.[FIELD_NAMES.LEG_ID],
    };

    updateLock(prepareBody).then((res) => {
      const newData = [...res.data.data];
      // This finds and replaces updated maps with keeping current sort.
      const updatedData = pricerTablesData?.map(
        (item) => newData.find((o) => o.id === item.id) || item
      );
      setIsCellLocking(false);
      setPricerData(updatedData);
    });
  };

  return (
    <div>
      {isLocked ? (
        <UnlockOutlined className="lock-icon__container" />
      ) : (
        <LockOutlined className="lock-icon__container" onClick={onIconClick} />
      )}
    </div>
  );
};

export default LockIcon;
