import {
  getVolSurfaceStickyStrikes,
  updateStickyStrikeCell,
} from "api/volSurfaceApi";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { revertCellValue } from "helpers/generalHelper";
import { updateVolSurfaceData } from "helpers/volSurfaceHelper";
import { useCallback } from "react";

function useUpdateStrikeTable({ toNextCell, gridRef }) {
  const {
    selectedFilters,
    setStickyStrikeData,
    setSurfaceTime,
    stickyStrikeData,
  } = useVolSurfaceControlTable();

  const onCellEditingStopped = useCallback(
    (event) => {
      const oldValue =
        event.oldValue === null ? event.oldValue : +event.oldValue;
      const newValue =
        event.oldValue === null ? event.newValue : +event.newValue;
    
      if (oldValue !== newValue && newValue !== null && !isNaN(newValue)) {
        var prepareBody = {
          vol_smile_id: event.data?.vol_smile_id,
          key: event.data?.key.toString(),
          risk_free: selectedFilters.risk_free || 0,
          calculation_date: selectedFilters?.strike_date.split(" ")[0],
        };

        const field = event.colDef.field;
        if (field === "underlying") {
          prepareBody.underlying = +event.newValue;
        } else if (field === "premium") {
          prepareBody.new_premium = +event.newValue;
        } else if (field === "strike") {
          prepareBody.new_strike = +event.newValue;
        }

        updateStickyStrikeCell(prepareBody)
          .then((res) => {
            if (res.status !== 200) {
              revertCellValue(event);
            } else {
              if (field === "underlying") {
                getVolSurfaceStickyStrikes({
                  product_contract_style_id: selectedFilters?.product_id,
                  date_time: selectedFilters?.strike_date,
                }).then((res) => {
                  setStickyStrikeData(res.data.data?.table);
                  setSurfaceTime(res.data.data?.time);
                });
              } else {
                const updatedData = updateVolSurfaceData(
                  [res.data.data],
                  stickyStrikeData
                );
                setStickyStrikeData(updatedData);
                refreshTable();
              }
            }
          })
          .catch((e) => {
            revertCellValue(event);
            console.log("Error while setting data");
          });
      }else{
        revertCellValue(event);
      }

      // go to next row
      if (toNextCell) {
        gridRef.current.api.startEditingCell({
          rowIndex: event.rowIndex + 1,
          colKey: event.column.colId,
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stickyStrikeData, toNextCell]
  );

  const refreshTable = () => {
    let refreshParams = { force: true };
    gridRef.current.api.refreshCells(refreshParams);
  };
  return { onCellEditingStopped };
}

export default useUpdateStrikeTable;
