import TeamRunReadOnlyAgGridTables from "components/TeamRunReadOnly/TeamRunReadOnlyAgGridTables";
import TeamRunReadonlyState from "contexts/TeamRunContextReadonly";

function TeamRunReadonly() {
    return (
      <TeamRunReadonlyState>
        <TeamRunReadOnlyAgGridTables />
      </TeamRunReadonlyState>
    );
}

export default TeamRunReadonly;
