import { getVolSurfaceStickyStrikes } from "api/volSurfaceApi";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { useContext, useEffect, useState } from "react";

function useStrikeTable() {
 const [isFetching, setIsFetching] = useState(false);
 const [gridHeight, setGridHeight] = useState("auto");

 const {
   globalState: { dark_Theme },
 } = useContext(GlobalContext);
 const {
   selectedFilters,
   stickyStrikeData,
   setStickyStrikeData,
   setSurfaceTime,
 } = useVolSurfaceControlTable();

 useEffect(() => {
   if (!selectedFilters?.product_id) return;
   setIsFetching(true);
   getVolSurfaceStickyStrikes({
     product_contract_style_id: selectedFilters?.product_id,
     date_time: selectedFilters?.strike_date,
   })
     .then((res) => {
       setStickyStrikeData(res.data.data?.table);
       setSurfaceTime(res.data.data?.time);
     })
     .finally(() => {
       setIsFetching(false);
     });
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [selectedFilters?.product_id, selectedFilters?.strike_date]);

 //handle grid height
 useEffect(() => {
   if (stickyStrikeData?.length === 0) return;
   const numberOfRowData = stickyStrikeData?.length;
   const calculatedHeight = numberOfRowData * 30 + 38;
   setGridHeight(`${calculatedHeight}px`);
 }, [stickyStrikeData]);

 return {
   isFetching,
   gridHeight,
   dark_Theme,
 };
}

export default useStrikeTable;
