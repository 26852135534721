/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";

import classnames from "classnames";
import { Divider, Tabs } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
// Helpers
import { ROUTES_PATH } from "routes";
import { GlobalContext } from "contexts/GlobalContext";
// Styles
import "./Navbar.scss";
import { NAVBAR_NAMES } from "constant/Navbar";
import NavbarMenu from "./NavbarMenu";

const Navbar = ({ navTabs }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState("");

  const tabsText = useMemo(() => {
    return navTabs;
  }, [navTabs]);

  const onTabClick = useCallback((tabClicked) => {
    if (tabClicked === NAVBAR_NAMES.PROFILE) {
      setActiveTab("");
      navigate(ROUTES_PATH.PROFILE.index);
      return;
    }
    navTabs.forEach((item) => {
      setActiveTab(item.label);

      if (item.label === tabClicked) navigate(item.path);
    });
  }, []);

  const showTabs = useMemo(() => {
    if (!tabsText.length) return;
    return tabsText.map((item) => {
      return { label: item.label, key: item.label, id: item.key };
    });
  }, []);

  useEffect(() => {
    const removeSlashes = location.pathname.replace(/[^a-zA-Z]/g, "");
    const findName = navTabs.find((item) => {
      return (
        item.path.replace(/[^a-zA-Z]/g, "").toLowerCase() === removeSlashes
      );
    });
    setActiveTab(findName.label);
  }, [location.pathname, navTabs]);

  const isPricerActive =
    window.location.pathname.indexOf(ROUTES_PATH.PRICER) > -1;
  return (
    <div
      className={classnames(
        dark_Theme || false ? "header__controls_dark" : "header__controls",
        { "header__controls--expand-to-full-screen-width": !isPricerActive }
      )}
    >
      <Tabs
        key={location.pathname}
        activeKey={activeTab}
        className="header-tabs"
        onChange={(tab) => onTabClick(tab)}
        items={showTabs}
        id="header-tabs"
      /> 
      
      <Divider key={"vertical"} type="vertical" className="vertical--divider" />
      <h3 key={"logo"} className="header--logo">
        {NAVBAR_NAMES.ARATUS}
      </h3>

      <NavbarMenu onTabClick={onTabClick} />
    </div>
  );
};

export default Navbar;
