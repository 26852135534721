import { Col, Button, Row } from "antd";
import { exportMarketAnalysis } from "api/marketAnalysisApi";
import { useState } from "react";

export default function DateAndExport({ dark_Theme, start_date, end_date }) {
  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportMarketAnalysis = () => {
    setIsExportLoading(true);
    let params = { start_date, end_date };
    exportMarketAnalysis(params)
      .then((response) => {
        const fileUrl = response.data.data.url;
        const a = document.createElement("a");
        a.href = fileUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsExportLoading(false);
      })
      .catch((error) => {
        setIsExportLoading(false);
        console.log("Error while exporting the data>>", error);
      });
  };

  
  return (
    <Col span={24}>
      <Row size={"middle"} className={`${dark_Theme && "date-picker-dark"}`}>
        <Col span={3}>
          <span style={{ whiteSpace: "nowrap" }}>Data to include:</span>
        </Col>
        <Col>
          <Button
            className={dark_Theme ? "ant-btn-dark" : "ant-btn-default"}
            onClick={handleExportMarketAnalysis}
            loading={isExportLoading}
          >
            Export
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
