import TraderState from "contexts/TraderContext";

import TeamRunPageLayout from "components/TeamRun/TeamRunLayout/TeamRunLayout";
import TraderAgGridTables from "components/Trader/TraderAgGridTables";

function Trader() {
    return (
      <TeamRunPageLayout>
        <TraderState>
          <TraderAgGridTables />
        </TraderState>
      </TeamRunPageLayout>
    );
}

export default Trader
