import {
  CopyOutlined,
  ContainerOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { commonStyle } from "constant/AgGridCommonStyle";
import { comparatorSorter } from "helpers/Pricer/Table";
import { ACTION_BUTTONS_NAME } from "../GeneralActionButtons";
import ActionColumn from "components/TeamRunReadOnly/TeamRunReadOnlyActionColumn";
import HeaderWithIcon from "components/Common/HeaderWithIcon";

// Constants for colors
export const DEFAULT_FONT_COLOR = "var(--color-white-1)";
export const ACTIVE_FONT_COLOR = "--color-black-1";
export const INACTIVE_FONT_COLOR = "grey";
export const DEFAULT_BACKGROUND_COLOR = "var(--color-white-1)";
export const UPDATE = "update";
export const COMPANY_ID = "company_id";
export const USER_ID = "user_id";
export const TRIGGER_ID = "trigger_id";
export const TEAMRUN_READONLY_STATE = "teamRun_readonly_state";
export const TEAM_RUN_READONLY_COULMN_STATE = "teamRun_readonly_columnState";
export const TEAM_RUN_READONLY_DRAGEABLE_STATE =
  "teamRun_readonly_dragableState";
export const TEAM_RUN_READONLY_SELECTED_ROWS = "teamRun_readonly_selectedRows";
export const TEAM_RUN_READONLY_COLORS_STATE = "teamrun_readonly_colorState";

export const TEAM_RUN_READONLY_COLUMNS = (headerName) => {
  return [
    {
      headerName: "",
      field: "",
      maxWidth: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { headerName, field: "title", headerComponent: HeaderWithIcon },
    { headerName: "Broker", field: "broker", editable: false },

    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      initialWidth: 130,
      minWidth: 130,
      cellRenderer: ActionColumn,
    },
    /// for sorting
    {
      headerName: "Commodity",
      field: "product",
      sortable: true,
      hide: true,
      sort: "asc",
      sortIndex: 1,
    },
    {
      headerName: "Contract",
      field: "contract",
      hide: true,
      sortable: true,
      sort: "asc",
      sortIndex: 2,
    },
    {
      headerName: "Tenor",
      sortIndex: 3,
      sort: "asc",
      field: "tenor",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Option Type",
      sortIndex: 4,
      field: "type",
      sort: "asc",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Strike",
      sortIndex: 5,
      sort: "asc",
      field: "strike",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Is Active",
      sortIndex: 0,
      sort: "desc",
      field: "is_active",
      hide: true,
      sortable: true,
    },
  ].map((item) => {
    return {
      ...item,
      cellStyle: (params) => {
        const totalRows = params.api.getDisplayedRowCount();
        if (totalRows === 1) {
          return {
            fontSize: commonStyle.fontSize,
          };
        }

        const isLastRow = params.node?.rowIndex === totalRows - 1;
        if (isLastRow) {
          return {
            fontSize: commonStyle.fontSize,
          };
        }

        if (!params.data.same_active) {
          return {
            fontSize: commonStyle.fontSize,
            border: "none",
            borderBottom: "4px solid orange",
          };
        }

        return {
          fontSize: commonStyle.fontSize,
          border: "none",
          borderBottom:
            params.data.different_period && params.data.is_active
              ? "4px solid #d9d9d9"
              : null,
        };
      },
      comparator: comparatorSorter(item),
      suppressMenu: true,
    };
  });
};

export const ACTION_COLUMN_MENU_ITEMS = [
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND.label,
    icon: <CopyOutlined />,
    index: 0,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.label,
    icon: <CopyOutlined />,
    index: 1,
  },

  {
    key: ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key,
    label: ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.label,
    icon: <AuditOutlined />,
    index: 8,
  },

  {
    key: ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key,
    label: ACTION_BUTTONS_NAME.ADDITIONAL_DATA.label,
    icon: <ContainerOutlined />,
    index: 10,
  },
];

export const teamRunTableHandler = {
  top: {
    cursor: "default",
  },
  bottom: { cursor: "default" },
  bottomLeft: { cursor: "default" },
  bottomRight: { cursor: "default" },
  topLeft: { cursor: "default" },
  topRight: { cursor: "default" },
  right: {
    width: "10px",
    height: "100%",
    backgroundColor: "var( --color-gray-5)",
    cursor: "col-resize",
  },
  left: {
    width: "10px",
    height: "100%",
    backgroundColor: "var( --color-gray-5)",
    cursor: "col-resize",
  },
};
