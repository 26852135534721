import { Col, Row, Select } from "antd";
import { getStrikeSurfaces } from "api/volSurfaceApi";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";

function StickyDeltaSurface({
  setSelectedFilters,
  selectedFilters,
}) {
  const [strikeSurfaces, setStrikeSurfaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

    const {
      globalState: { dark_Theme },
    } = useContext(GlobalContext);


    useEffect(() => {
      setIsLoading(true);
      getStrikeSurfaces({
        product_contract_style_id: selectedFilters?.product_id,
      })
        .then((res) => {
          setStrikeSurfaces(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error >>", err);
        });
    }, [selectedFilters?.product_id]);

    function handleSelectChange(value) {
        setSelectedFilters((prev) => ({
          ...prev,
          sticky_surface: {
            id: value,
            label: strikeSurfaces.find((item) => item.id === value)?.title
          },
        }));
    }

  return (
    <Col span={24}>
      <Row gutter={[25, 25]}>
        <Col span={3}>Sticky Delta Surface:</Col>
        <Col>
          <Select  
            placeholder="Select Delta Surface"
            className={dark_Theme ? "select-dark" : ""}
            popupClassName={dark_Theme ? "dropdown-dark" : ""}
            style={{ width: 180 }}
            allowClear
            value={selectedFilters?.sticky_surface?.label}
            disabled={isLoading}
            loading={isLoading}
            onChange={(e) => {
              handleSelectChange(e);
            }}
            showSearch
            filterOption={(input, option) => {
              const inputSmallLetter = input.toLowerCase();
              const label = option.label.toLowerCase();
              return label.includes(inputSmallLetter);
            }}
            options={strikeSurfaces?.map((data) => ({
              value: data.id,
              label: data.title,
            }))}
          />
        </Col>
      </Row>
    </Col>
  );
}

export default StickyDeltaSurface
