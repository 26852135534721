import {PARAMS_FRONTEND_ROUNDING_AND_FORMATTING} from '../../constant/Pricer/PricerTable'

export const roundAndFormat = (value,field) => {
    let fieldKey = field?.replace('bid','premium').replace('ask','premium');
    if(!(fieldKey in PARAMS_FRONTEND_ROUNDING_AND_FORMATTING)) return value;
    let returnValue = value;
    const roundedTo = PARAMS_FRONTEND_ROUNDING_AND_FORMATTING[fieldKey].roundedTo;
    const formattedTo = PARAMS_FRONTEND_ROUNDING_AND_FORMATTING[fieldKey].formattedTo;
    const showMinDecimals = 'showMinDecimals' in PARAMS_FRONTEND_ROUNDING_AND_FORMATTING[fieldKey];
    returnValue = Math.round(value * Math.pow(10, Number(roundedTo))) / Math.pow(10, Number(roundedTo))
    returnValue = Number(returnValue).toFixed(formattedTo);
    if(showMinDecimals) returnValue = Number(returnValue);
    return returnValue;
}


export const updatedPricerData = (newData, pricerTablesData) => {
    const updatedData = pricerTablesData?.map((item) => {
      const updatedRow = newData.find((o) => o.id === item.id);
      if (updatedRow) {
        return updatedRow;
      }
      return item;
    });
    return updatedData;
  };
  