import { Col } from "antd";
import AgGridTable from "components/AuditTrail/AgGridTable";

function VolSurfaceTable({
  volSurfaceButtonClicked,
  start_date,
  end_date,
  setStart_date,
  setEnd_date,
  setInitial_date,
  selectedFiltersParams,
  dark_Theme,
}) {
  return (
    <Col
      span={24}
      style={{ margin: "auto, -20px" }}
      className={`${
        dark_Theme || false
          ? "pricer-ag-grid__container_dark"
          : "pricer-ag-grid__container"
      }`}
    >
      <AgGridTable
        volSurfaceButtonClicked={volSurfaceButtonClicked}
        start_date={start_date}
        selectedFilters={selectedFiltersParams}
        end_date={end_date}
        setStart_date={setStart_date}
        setEnd_date={setEnd_date}
        setInitial_date={setInitial_date}
      />
    </Col>
  );
}

export default VolSurfaceTable
