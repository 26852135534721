import { useContext } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import api from "api/api";
import { TraderContext } from "contexts/TraderContext";

export const prepareBody = (response) => {
  if (_.isEmpty(response)) return;
  let sortedBody = {};
  Object.keys(response).forEach((item) => {
    const { title, rows } = response[item];
    sortedBody = {
      ...sortedBody,
      [title]: rows,
    };
  });
  return sortedBody;
};
const useTrader = () => {
  const { setTeamRunData, setProductIds } = useContext(TraderContext);

  // APIS FUNC
  useQuery(
    ["trader_products"],
    async () =>
      await api
        .get("team-run/products")
        .then((res) => res.data.data)
        .catch((err) => console.error({ err })),
    {
      onSuccess: (product) => setProductIds(product),
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,
    }
  );

  const { isFetching } = useQuery(
    ["trader"],
    async () =>
      await api
        .get("trader")
        .then((res) => res.data.data)
        .catch((err) => console.error({ err })),
    {
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,
      onSuccess: (successItem) => {
        const res = prepareBody(successItem);
        setTeamRunData(res);
      },
    }
  );

  return isFetching;
};

export default useTrader;
