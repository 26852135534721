import { CopyOutlined } from "@ant-design/icons";
import HeaderWithIcon from "components/Common/HeaderWithIcon";
import { commonStyle } from "constant/AgGridCommonStyle";
import { comparatorSorter } from "helpers/Pricer/Table";
import { ACTION_BUTTONS_NAME } from "../GeneralActionButtons";
import TraderActionsColumn from "components/Trader/TraderActionsColumn";

 // Constants for default colors
 export const DEFAULT_FONT_COLOR_LIGHT = "var(--color-black-1)";
export const DEFAULT_FONT_COLOR_DARK = "var(--color-white-1)";
 export const DEFAULT_BACKGROUND_COLOR = "var(--color-white-1)";
export const UPDATE = "update";
export const COMPANY_ID = "company_id";
export const USER_ID = "user_id";
export const TRIGGER_ID = "trigger_id";
export const TrADER_STATE = "trader_state";
export const TRADER_COULMN_STATE = "trader_columnState";
export const TRADER_DRAGEABLE_STATE = "trader_dragableState";
export const TRADER_SELECTED_ROWS = "trader_selectedRows";
export const TRADER_COLORS_STATE = "trader_colorState";

export const TRADER_COLUMNS = (headerName) => {
  return [
    {
      headerName: "",
      field: "",
      maxWidth: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { headerName, field: "title", headerComponent: HeaderWithIcon },
    { headerName: "Broker", field: "broker", editable: false },
    {
      headerName: "Actions",
      field: "actions",
      initialWidth: 130,
      minWidth: 130,
      cellRenderer: TraderActionsColumn,
    },
    /// for sorting
    {
      headerName: "Commodity",
      field: "product",
      sortable: true,
      hide: true,
      sort: "asc",
      sortIndex: 1,
    },
    {
      headerName: "Contract",
      field: "contract",
      hide: true,
      sortable: true,
      sort: "asc",
      sortIndex: 2,
    },
    {
      headerName: "Tenor",
      sortIndex: 3,
      sort: "asc",
      field: "tenor",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Option Type",
      sortIndex: 4,
      field: "type",
      sort: "asc",
      hide: true,
      sortable: true,
    },
    {
      headerName: "Strike",
      sortIndex: 5,
      sort: "asc",
      field: "strike",
      hide: true,
      sortable: true,
    },
  ].map((item) => {
    return {
      ...item,
      cellStyle: (params) => {
        const totalRows = params.api.getDisplayedRowCount();
        if (totalRows === 1) {
          return {
            fontSize: commonStyle.fontSize,
          };
        }

        const isLastRow = params.node?.rowIndex === totalRows - 1;
        if (isLastRow) {
          return {
            fontSize: commonStyle.fontSize,
          };
        }

        return {
          fontSize: commonStyle.fontSize,
          border: "none",
          borderBottom: params.data.different_period
            ? "4px solid #d9d9d9"
            : null,
        };
      },
      comparator: comparatorSorter(item),
      suppressMenu: true,
    };
  });
};

export const ACTION_COLUMN_MENU_ITEMS = [
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND.label,
    icon: <CopyOutlined />,
    index: 0,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.label,
    icon: <CopyOutlined />,
    index: 1,
  },
  {
    key: ACTION_BUTTONS_NAME.EXPORT_ROWS.key,
    label: ACTION_BUTTONS_NAME.EXPORT_ROWS.label,
    icon: ACTION_BUTTONS_NAME.EXPORT_ROWS.icon,
    index: 2,
  },
];
