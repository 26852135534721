import { useEffect, useMemo, useState } from "react";
// Context
import { getTeamRunProductById } from "api/teamRunApi";
import { GEEKS_USER } from "constant/Profile/Profile";
import {
  COMPANY_ID,
  TRIGGER_ID,
  UPDATE,
  USER_ID,
} from "constant/TeamRun/TeamRun";
import { getDataFromLocalStorage } from "helpers/generalHelper";
import usePusher from "./usePusher";
const geeksUser = getDataFromLocalStorage(GEEKS_USER);

const useWebSocket = () => {
  const { isConnected, subscribe, unsubscribe } = usePusher();

  const [data, setData] = useState(null);
  const [title, setTitle] = useState(null);
  const [product_id, setProduct_id] = useState(null);

  const SOCKET_EVENT_DATA = useMemo(
    () => ({
      CHANNEL_NAME: `team-run.${geeksUser?.[COMPANY_ID]}`,
      EVENT_NAME: "team-run-notification",
    }),
    []
  );

  useEffect(() => {
    if (!isConnected) return;

    const handleNotification = (data) => {
      if (data[TRIGGER_ID] !== geeksUser[USER_ID]) {
        if (data.action === UPDATE) {
          let title = data?.product_title;
          let dataToUpdate = data?.data;
          setData(dataToUpdate);
          setTitle(title);
        } else {
          setTitle(null);
          getTeamRunProductById(data.product_id).then((res) => {
            const response = res.data.data;
            if (!response) return;
            setData(response);
            setProduct_id(data.product_id);
          });
        }
      }
    };

    subscribe(
      SOCKET_EVENT_DATA.CHANNEL_NAME,
      SOCKET_EVENT_DATA.EVENT_NAME,
      handleNotification
    );

    return () => {
      unsubscribe(
        SOCKET_EVENT_DATA.CHANNEL_NAME,
        SOCKET_EVENT_DATA.EVENT_NAME,
        handleNotification
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, subscribe, unsubscribe]);

  return {
    data,
    title,
    product_id,
  };
};

export default useWebSocket;
