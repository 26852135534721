import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import api from "api/api";
import { useQuery } from "@tanstack/react-query";
import { FIELD_NAMES } from "constant/Pricer/PricerTable";
import { PRICER_OPT_INFO } from "constant/Profile/Profile";
import { setDataToLocalStorage } from "helpers/generalHelper";
import { OPTION_ROWS_END_POINT } from "api/pricerApi";

// List of initial state for all global data
export const initialState = {
  pricerTablesData: "",
  isLoading: false,
  selectedRows: [],
  dateSelected: null,
  filterButtonSelected: null,
  pricerOptInfo: null,
  filterButtonNames: [],
  pricerRefAgGrid: null,
  isAddOptionSubmitted: false,
  isCellEditing: false,
  isCellLocking: false,
  cellLockingSummaryId: null,
  refetchingRowsAfterCalcDateChange: false,
  isPushingToTeamRunOrAuditTrail: false,
  rowEditingData: null,
  pricerParamsAPI: null,
};

// Actions
const SET_PRICER_DATA = "SET_PRICER_DATA";
const SET_IS_PRICER_FETCHING = "SET_IS_PRICER_FETCHING";
const SET_IS_PRICER_FINISHED_FETCHING = "SET_IS_PRICER_FINISHED_FETCHING";
const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
const SET_DATE_SELECTED = "SET_DATE_SELECTED";
const SET_FILTER_BUTTON_SELECTED = "SET_FILTER_BUTTON_SELECTED";
const SET_FILTER_BUTTONS_NAME = "SET_FILTER_BUTTONS_NAME";
const SET_PRICER_AG_GRID_REF = "SET_PRICER_AG_GRID_REF";
const SET_IS_ADD_OPTION_SUBMITTED = "SET_IS_ADD_OPTION_SUBMITTED";
const SET_IS_CELL_EDITING = "SET_IS_CELL_EDITING";
const SET_IS_CELL_Locking = "SET_IS_CELL_Locking";
const SET_CELL_Locking_SUMMARY_ID = "SET_CELL_Locking_SUMMARY_ID";
const SET_REFETCHING_ROWS_AFTER_CALC_DATE_CHANGE =
  "SET_REFETCHING_ROWS_AFTER_CALC_DATE_CHANGE";
const SET_IS_PUSHING_TO_TEAM_RUN_OR_AUDIT_TRAIL =
  "SET_IS_PUSHING_TO_TEAM_RUN_OR_AUDIT_TRAIL";
const SET_ROW_EDITING_DATA = "SET_ROW_EDITING_DATA";
const SET_PRICER_PARAMS = "SET_PRICER_PARAMS";
const SET_PRICER_OPERATIONS = "SET_PRICER_OPERATIONS";

// Shared Reducer For Global Context
const Reducer = (state, action) => {
  switch (action.type) {
    case SET_IS_PRICER_FETCHING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };
    case SET_IS_PRICER_FINISHED_FETCHING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_FILTER_BUTTON_SELECTED:
      return {
        ...state,
        filterButtonSelected: action.payload,
      };
    case SET_PRICER_AG_GRID_REF:
      return {
        ...state,
        pricerRefAgGrid: action.payload,
      };
    case SET_PRICER_OPERATIONS:
      return {
        ...state,
        pricerOptInfo: action.payload,
      };
    case SET_FILTER_BUTTONS_NAME:
      return {
        ...state,
        filterButtonNames: action.payload,
      };
    case SET_DATE_SELECTED:
      return {
        ...state,
        dateSelected: action.payload,
      };
    case SET_PRICER_DATA:
      return {
        ...state,
        pricerTablesData: action.payload,
        isLoading: false,
      };
    case SET_IS_ADD_OPTION_SUBMITTED:
      return {
        ...state,
        isAddOptionSubmitted: action.payload,
      };
    case SET_IS_CELL_EDITING:
      return {
        ...state,
        isCellEditing: action.payload,
      };
    case SET_IS_CELL_Locking:
      return {
        ...state,
        isCellLocking: action.payload,
      };
    case SET_CELL_Locking_SUMMARY_ID:
      return {
        ...state,
        cellLockingSummaryId: action.payload,
      };
    case SET_REFETCHING_ROWS_AFTER_CALC_DATE_CHANGE:
      return {
        ...state,
        refetchingRowsAfterCalcDateChange: action.payload,
      };
    case SET_IS_PUSHING_TO_TEAM_RUN_OR_AUDIT_TRAIL:
      return {
        ...state,
        isPushingToTeamRunOrAuditTrail: action.payload,
      };
    case SET_ROW_EDITING_DATA:
      return {
        ...state,
        rowEditingData: action.payload,
      };
    case SET_PRICER_PARAMS:
      return {
        ...state,
        pricerParamsAPI: action.payload,
      };
    default:
      return state;
  }
};

// Global State Which its provide context for children
const PricerState = ({ children, defaultInitialState = {} }) => {
  const [state, dispatch] = useReducer(Reducer, {
    ...initialState,
    ...defaultInitialState,
  });

  const setPricerData = useCallback((value) => {
    dispatch({ type: SET_PRICER_DATA, payload: value });
  }, []);

  const setSelectedRowsDispatch = useCallback((value) => {
    dispatch({ type: SET_SELECTED_ROWS, payload: value });
  }, []);

  const setDateSelected = useCallback((value) => {
    dispatch({ type: SET_DATE_SELECTED, payload: value });
  }, []);

  const setIsOptionRowLoading = useCallback((value) => {
    if (value) {
      dispatch({ type: SET_IS_PRICER_FETCHING, payload: value });
      return;
    }
    dispatch({ type: SET_IS_PRICER_FINISHED_FETCHING, payload: value });
  }, []);

  const setFilterButtonSelected = useCallback((value) => {
    dispatch({ type: SET_FILTER_BUTTON_SELECTED, payload: value });
  }, []);

  const setPricerOperation = useCallback((value) => {
    dispatch({ type: SET_PRICER_OPERATIONS, payload: value });
  }, []);

  const setFilterButtonNames = useCallback((value) => {
    dispatch({ type: SET_FILTER_BUTTONS_NAME, payload: value });
  }, []);

  const setPricerAgGridRef = useCallback((value) => {
    dispatch({ type: SET_PRICER_AG_GRID_REF, payload: value });
  }, []);

  const setIsAddOptionSubmitted = useCallback((value) => {
    dispatch({ type: SET_IS_ADD_OPTION_SUBMITTED, payload: value });
  }, []);

  const setIsCellEditing = useCallback((value) => {
    dispatch({ type: SET_IS_CELL_EDITING, payload: value });
  }, []);

  const setIsCellLocking = useCallback((value) => {
    dispatch({ type: SET_IS_CELL_Locking, payload: value });
  }, []);

  const setCellLockingSummaryId = useCallback((value) => {
    dispatch({ type: SET_CELL_Locking_SUMMARY_ID, payload: value });
  }, []);

  const setIsPushingToTeamRunOrAuditTrail = useCallback((value) => {
    dispatch({
      type: SET_IS_PUSHING_TO_TEAM_RUN_OR_AUDIT_TRAIL,
      payload: value,
    });
  }, []);

  const setRefetchingRowsAfterCalcDateChange = useCallback((value) => {
    dispatch({
      type: SET_REFETCHING_ROWS_AFTER_CALC_DATE_CHANGE,
      payload: value,
    });
  }, []);

  const setRowEditingData = useCallback((value) => {
    dispatch({ type: SET_ROW_EDITING_DATA, payload: value });
  }, []);

  const setPricerParams = useCallback((value) => {
    dispatch({ type: SET_PRICER_PARAMS, payload: value });
  }, []);

  const { refetch, isFetching } = useQuery(
    ["option-rows"],
    () =>
      api
        .get(
          `${OPTION_ROWS_END_POINT}?${FIELD_NAMES.CALCULATION_DATE}=${
            state.dateSelected ? state.dateSelected : null
          }`
        )
        .then((res) => res.data.data)
        .catch((err) => {
          console.error({ err });
          throw err;
        }),
    {
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,
      onSuccess: (successItem) => {
        setPricerData(successItem);
        return successItem;
      },
    }
  );

  useEffect(() => {
    setIsOptionRowLoading(isFetching);
    setRefetchingRowsAfterCalcDateChange(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (!state.pricerOptInfo) return;
    setDataToLocalStorage(PRICER_OPT_INFO, state.pricerOptInfo);
  }, [state.pricerOptInfo]);

  useEffect(() => {
    refetch();
  }, [state.dateSelected]);

  const contextValue = useMemo(() => {
    return {
      state,
      refetchOptionRow: refetch,
      isOptionRowFetching: isFetching,
      setSelectedRowsDispatch,
      setIsPushingToTeamRunOrAuditTrail,
      setPricerData,
      setFilterButtonSelected,
      setDateSelected,
      setFilterButtonNames,
      setPricerAgGridRef,
      setIsAddOptionSubmitted,
      setIsCellEditing,
      setIsCellLocking,
      setCellLockingSummaryId,
      setPricerParams,
      setRowEditingData,
      setPricerOperation,
      setIsOptionRowLoading,
    };
  }, [state]);

  return (
    <PricerContext.Provider value={contextValue}>
      {children}
    </PricerContext.Provider>
  );
};

// Create Global Context
export const PricerContext = createContext(initialState);

// Export Global State Context Component
export default PricerState;
