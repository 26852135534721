import { Button, Col, Space } from "antd";
import classNames from "classnames";
import { GRAPH_TABLE_FILTER_LABELS } from "constant/GeneralActionButtons";
import { VOL_SURFACE_BUTTON_TAB } from "constant/VolSurface";
import { GlobalContext } from "contexts/GlobalContext";
import { getDataFromLocalStorage, setDataToLocalStorage } from "helpers/generalHelper";
import { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

function GraphOrTableTabs({ selectedTab, setSelectedTab }) {
   const location = useLocation();
   const {
     globalState: { dark_Theme },
   } = useContext(GlobalContext);


     useEffect(() => {
       let volSurfaceBtn = getDataFromLocalStorage(VOL_SURFACE_BUTTON_TAB);
       if (!volSurfaceBtn) {
         setSelectedTab("Graphs");
       } else {
         setSelectedTab(volSurfaceBtn);
       }
     }, [setSelectedTab]);



    useEffect(() => {
      if (selectedTab) {
        setDataToLocalStorage(VOL_SURFACE_BUTTON_TAB, selectedTab);
      }
    }, [selectedTab]);

  const showTradeButtons = useMemo(() => {
    let keys = location.pathname.includes("market-analysis")
      ? Object.keys(GRAPH_TABLE_FILTER_LABELS).reverse()
      : Object.keys(GRAPH_TABLE_FILTER_LABELS);
    return keys.map((item, index) => {
      return (
        <Button
          className={classNames({
            "ant-btn-dark": dark_Theme,
            "selected-button": GRAPH_TABLE_FILTER_LABELS[item] === selectedTab,
          })}
          key={GRAPH_TABLE_FILTER_LABELS[item] + index}
          onClick={() => setSelectedTab(GRAPH_TABLE_FILTER_LABELS[item])}
        >
          {GRAPH_TABLE_FILTER_LABELS[item]}
        </Button>
      );
    });
  }, [dark_Theme, location.pathname, selectedTab, setSelectedTab]);

  return (
    <Col span={24}>
      <Space>{showTradeButtons}</Space>
    </Col>
  );
}

export default GraphOrTableTabs;
