import api from "./api";

const AUDIT_TRAIL_END_POINT = "/audit-trails";
const PRODUCTS_END_POINT = `${AUDIT_TRAIL_END_POINT}/products`;
const FILTERS_END_POINT = `${AUDIT_TRAIL_END_POINT}/filters`;
const DELETE_AUDIT_TRAIL_END_POINT = `${AUDIT_TRAIL_END_POINT}/delete`;

export const fetchAdditionalData = async (rowSelectedInfo) => {
  return await api.get(
    `${AUDIT_TRAIL_END_POINT}/${rowSelectedInfo?.summary_id}/additional-info`
  );
};

export const fetchTrailData = async (queryParams) => {
  return await api.get(`${AUDIT_TRAIL_END_POINT}?`, { ...queryParams });
};

export const fetchAuditTrailPages = async (id, queryParams) => {
  return await api.get(`${AUDIT_TRAIL_END_POINT}/${id}`, { ...queryParams });
};

export const fetchProducts = async () => {
  return await api.get(PRODUCTS_END_POINT);
};

export const getFilterButtons = async () => {
  return await api.get(FILTERS_END_POINT);
};

export const deleteAuditTrailRow = async (audit_trail_id) => {
  return await api.post(DELETE_AUDIT_TRAIL_END_POINT, {
    audit_trail_id: audit_trail_id,
  });
};
