import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { VolSurfaceContext } from "contexts/VolSurfaceContext";
import { useIsFetching } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import "styles/PricerAuditAgGridLayout.scss";
import { Col, Row } from "antd";
import { Spin } from "antd/lib";
import {
  fetchUserVolSurface,
  updateUserVolSurfaceTitle,
} from "api/volSurfaceApi";
import { GlobalContext } from "contexts/GlobalContext";
import FilterButton from "../Common/FilterButtons/FilterButtons";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "helpers/generalHelper";
import {
  SCROLL_POSITION,
  VOL_SURFACE_GRAPGH_FILTERS,
} from "constant/VolSurface";
import { toast } from "helpers/generalHelper";

import AgGridControlTable from "./AgGridControlTable/AgGridControlTable";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import GraphsContainer from "./VolSurfaceGraph/GraphsContainer";
import VolSurfaceDataTable from "components/VolSurfaceData/VolSurfaceDataTable";

import "./style.scss";
import { onUpdateGraphTitleHelper } from "helpers/volSurfaceHelper";
import StickyStrikeContainer from "./StickyStrike/StickyStrikeContainer";
import VolSurfaceTable from "./VolSurfaceTable";
function VolSurfaceContainer() {
  const rowRef = useRef(null);
  const [start_date, setStart_date] = useState();
  const [dateTime, setDateTime] = useState("");
  const [currentTab, setCurrentTab] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [end_date, setEnd_date] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [comparisonGraph, setComparisonGraph] = useState(
    getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS)?.comparison_surface_id
  );

  const [periodTypeFilterLoading] = useState(false);
  const [biasIncreaseDecrease, setBiasIncreaseDecrease] = useState(0);
  const [batchUpdate, setBatchUpdate] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;
  const [volSurfaceControl, setVolSurfaceControl] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [volSurfaceButtonClicked, setVolSurfaceButtonClicked] = useState("");
  const [isRiskFreeRevert, setIsRiskFreeRevert] = useState(false);
  const isFetching = useIsFetching();
  const [initial_date, setInitial_date] = useState({
    start_date: "",
    end_date: "",
  });

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const {
    state: { volSurfaceData,volSurfaceFilterShortcuts },
    setUserVolSurfaces,
    setSelectedFiltersData,
  } = useContext(VolSurfaceContext);

  const {
    scrollPosition,
    volSurfaceTablesData: rowsData,
    risk_free,
    surfaceId,
  } = useVolSurfaceControlTable();

  const scrollableRef = useRef(null);
  const volSurfaceDataPath = pathName.includes("vol-surface-data");
  // useEffect that renders for the first time only when the  component load and get the selectedFilters from the local storage and set in the component state.
  useEffect(() => {
    let localFilterData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
    const logLevel = localStorage.getItem("Reset_again");
    if (!logLevel) {
      localStorage.removeItem(VOL_SURFACE_GRAPGH_FILTERS);
      localStorage.removeItem("reset");
      localFilterData = {};
      localStorage.setItem("Reset_again", true);
    }
    const isFilterExist =
      localFilterData && Object.keys(localFilterData).length > 0;
    if (isFilterExist) {
      if (localFilterData.hasOwnProperty("start_date")) {
        setStart_date(localFilterData.start_date);
        setEnd_date(localFilterData.end_date);
        delete localFilterData.start_date;
        delete localFilterData.end_date;
      }
      setSelectedFilters(localFilterData);
    }
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (volSurfaceData) {
      let localFilterData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      if (
        !localFilterData.hasOwnProperty("start_date") &&
        volSurfaceData.default_end_date &&
        volSurfaceData.default_start_date
      ) {
        setStart_date(volSurfaceData.default_start_date);
        setEnd_date(volSurfaceData.default_end_date);
      }
    }
  }, [volSurfaceData]);

  // useEffect for storing the selected filters data in LocalStorage whenever the selectedFilters change
  useEffect(() => {
    if (!firstRender) {
      let localData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      let updatedFilters = {};
      if (localData) {
        updatedFilters = {
          ...localData,
        };
      }
      updatedFilters = { ...updatedFilters, start_date, end_date };
      setDataToLocalStorage(VOL_SURFACE_GRAPGH_FILTERS, updatedFilters);
    }
  }, [start_date, end_date]);

  // useEffect for storing the selected filters data in LocalStorage whenever the selectedFilters change
  useEffect(() => {
    if (!firstRender) {
      let localData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      let updatedFilters = {};
      if (localData) {
        updatedFilters = {
          ...localData,
        };
      }
      if (selectedFilters) {
        updatedFilters = { ...updatedFilters, ...selectedFilters };
      }
      setDataToLocalStorage(VOL_SURFACE_GRAPGH_FILTERS, updatedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setSelectedFiltersData(selectedFilters);
  }, [selectedFilters, setSelectedFiltersData]);

  // UseEffect will run whenever the user will change the "Set Live Surface" dropdown value...

  useEffect(() => {
    const oldScrollPosition = getDataFromLocalStorage(SCROLL_POSITION);
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = oldScrollPosition || scrollPosition;
    }
  }, [rowsData, scrollPosition]);

  useEffect(() => {
    if (volSurfaceDataPath) return;
    if (selectedFilters["product_id"]) {
      fetchUserVolSurface({
        product_contract_style_id: selectedFilters["product_id"],
      }).then((res) => {
        setUserVolSurfaces(res.data.data);
      });
    }
  }, [selectedFilters["product_id"]]);

  /**
   * Function to update the graph title and perform related operations.
   */
  const onUpdateGraphTitle = () => {
    if (volSurfaceDataPath) return;
    const { reqBody, querParams } = onUpdateGraphTitleHelper(
      volSurfaceControl,
      volSurfaceData,
      selectedFilters
    );

    if (currentTab === "Sticky Strike"){
      reqBody["date_time"] = selectedFilters?.strike_date;
       updateUserVolSurfaceTitle(reqBody, currentTab === "Sticky Strike").then(
         (res) => {
           toast(res?.data?.message, "success");
         }
       );
       return;
    }
    
    if (volSurfaceData && Object.keys(volSurfaceData).length > 0) {
      setVolSurfaceControl("");
      updateUserVolSurfaceTitle(
        reqBody,
        currentTab === "Sticky Strike"
      ).then((res) => {
        toast(res?.data?.message, "success");
        fetchUserVolSurface(querParams).then((res) => {
          setUserVolSurfaces(res.data.data);
        });
      });
    }
  };

  const onBiasChange = (is_increase) => {
    if (is_increase) {
      setBiasIncreaseDecrease((prev) => prev + 1);
    } else {
      setBiasIncreaseDecrease((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (!rowRef.current) return;
    const handleScroll = (e) => {
      if (rowRef.current.scrollTop > 630) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    const myDiv = document.querySelector(".my-vol-surface");
    myDiv.addEventListener("scroll", handleScroll);
    return () => {
      myDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const selectedFiltersParams = useMemo(() => {
    return {
      product_contract_style_id: selectedFilters?.product_id,
      risk_free: selectedFilters?.risk_free || 0,
    };
  }, [selectedFilters?.product_id, selectedFilters?.risk_free]);

  const selectedFiltersGraphParams = useMemo(() => {
    return selectedFilters;
  }, [selectedFilters]);

  return (
    <Row
      ref={rowRef}
      className={`my-vol-surface ${isSticky ? "sticky" : ""}`}
      justify={"start"}
    >
      <Col span={24}>
        <Col
          style={{
            padding: 20,
            height:
              volSurfaceButtonClicked !== "Table" &&
              !volSurfaceDataPath &&
              currentTab === "Sticky Delta" &&
              "600px",
          }}
        >
          <FilterButton
            setSelectedTab={setVolSurfaceButtonClicked}
            selectedTab={volSurfaceButtonClicked}
            start_date={start_date}
            end_date={end_date}
            initial_date={initial_date}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            onBiasChange={onBiasChange}
            batchUpdate={batchUpdate}
            onUpdateGraphTitle={onUpdateGraphTitle}
            periodTypeFilterLoading={periodTypeFilterLoading}
            setComparisonGraph={setComparisonGraph}
            comparisonGraph={comparisonGraph}
            isRiskFreeRevert={isRiskFreeRevert}
            setIsRiskFreeRevert={setIsRiskFreeRevert}
            initialRiskFree={risk_free}
            surface_id={surfaceId}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setEnd_date={setEnd_date}
            setStart_date={setStart_date}
            dateTime={dateTime}
            setDateTime={setDateTime}
          />
        </Col>

        {pathName === "/vol-surface" && (
          <>
            {currentTab === "Sticky Delta" && (
              <>
                {volSurfaceButtonClicked === "Table" ? (
                  <VolSurfaceTable
                    volSurfaceButtonClicked={volSurfaceButtonClicked}
                    start_date={start_date}
                    selectedFilters={selectedFiltersParams}
                    end_date={end_date}
                    setStart_date={setStart_date}
                    setEnd_date={setEnd_date}
                    setInitial_date={setInitial_date}
                    dark_Theme={dark_Theme}
                    selectedFiltersParams={selectedFiltersParams}
                  />
                ) : !selectedFilters["product_id"] ? null : (
                  <Col
                    span={24}
                    style={{ padding: "20px", height: "fit-content" }}
                  >
                    {isFetching ? (
                      <div className="vol-surface-spin">
                        <Spin size="large" />
                      </div>
                    ) : (
                      <>
                        <Row gutter={[24, 40]} justify={"center"}>
                          <AgGridControlTable
                            scrollableRef={scrollableRef}
                            start_date={start_date}
                            end_date={end_date}
                          />
                          {selectedFilters &&
                            selectedFiltersGraphParams?.product_id && (
                              <GraphsContainer
                                scrollRef={scrollableRef}
                                setIsRiskFreeRevert={setIsRiskFreeRevert}
                                setBatchUpdate={setBatchUpdate}
                                selectedFilters={selectedFiltersGraphParams}
                                end_date={end_date}
                                setBiasIncreaseDecrease={
                                  setBiasIncreaseDecrease
                                }
                                start_date={start_date}
                                biasIncreaseDecrease={biasIncreaseDecrease}
                              />
                            )}
                        </Row>
                      </>
                    )}
                  </Col>
                )}
              </>
            )}
            <>
              {(currentTab === "Sticky Strike" && volSurfaceFilterShortcuts && selectedFilters.shortcutFilter)  && (
                <StickyStrikeContainer currentTab={currentTab} />
              )}
            </>
          </>
        )}

        <Col span={24} style={{ padding: "20px", height: "fit-content" }}>
          {isFetching ? (
            <div className="vol-surface-spin">
              <Spin size="large" />
            </div>
          ) : (
            <>{volSurfaceDataPath && <VolSurfaceDataTable />}</>
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default VolSurfaceContainer;
