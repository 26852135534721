import CustomTradeInputForDelta from "components/Common/CustomTradeInputForDelta/CustomTradeInputForDelta";

function HedgeItem({
  hedge,
  placeholdersTitle,
  isSellActive,
  setIsRequestActive,
  handleHedgeQuantityChange,
}) {
  return (
    <CustomTradeInputForDelta
      isDelta={true}
      hedge={hedge}
      placeholdersTitle={placeholdersTitle}
      isSellActive={isSellActive}
      handleHedgeQuantityChange={handleHedgeQuantityChange}
      setIsRequestActive={setIsRequestActive}
    />
  );
}

export default HedgeItem;
