import { Select } from "antd";
import { getTradersByTradingCompanyId } from "api/teamRunApi";
import {
  API_KEYS,
  BACKEND_KEYS_NAME,
} from "constant/EditTradeDetails/EditTradeDetails";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";

function TraderDropdown({
  item,
  onChange,
  tradingCompanyId,
  apiData,
  setTraderId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [traders, setTraders] = useState([]);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  useEffect(() => {
    fetchTraders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingCompanyId]);

  const fetchTraders = async () => {
    if (!tradingCompanyId) return;
    setIsLoading(true);
    try {
      const response = await getTradersByTradingCompanyId(tradingCompanyId);
      setTraders(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onChange(BACKEND_KEYS_NAME.TRADER, null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [tradingCompanyId])

  return (
    <Select
      placeholder={
        apiData?.[API_KEYS.TRADE_PLACEHOLDER] || "Search Trading Companies"
      }
      value={item[BACKEND_KEYS_NAME.TRADER]}
      onChange={(value) => {
        const label = traders?.find((item) => item?.trader_id === value)?.label;
        onChange(BACKEND_KEYS_NAME.TRADER, label);
        setTraderId(value);
      }}
      allowClear
      showSearch
      filterOption={(input, option) => {
        const inputSmallLetter = input.toLowerCase();
        const label = option.children.toLowerCase();
        return label.includes(inputSmallLetter);
      }}
      disabled={isLoading}
      defaultValue={apiData?.trader}
      loading={isLoading}
      style={{ width: 180 }}
      notFoundContent={
        <p
          style={{ color: dark_Theme ? "gray" : "black", textAlign: "center" }}
        >
          Please select trading company first
        </p>
      }
      className={
        dark_Theme
          ? "select-dark edit-company-trader-input"
          : "edit-company-trader-input"
      }
      popupClassName={dark_Theme ? "dropdown-dark" : ""}
    >
      {tradingCompanyId &&
        traders.map((item) => (
          <Select.Option key={item.trader_id} value={item.trader_id}>
            {item.label}
          </Select.Option>
        ))}
    </Select>
  );
}

export default TraderDropdown;
