// usePusher.js
import { useState, useEffect, useCallback, useRef } from "react";
import Pusher from "pusher-js";

 const PUSHER_CREDENTIALS = {
  APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
  CLUSTER: process.env.REACT_APP_PUSHER_APP_CLUSTER,
};

const usePusher = () => {
  const [pusher, setPusher] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const channels = useRef({});

  useEffect(() => {
    // Initialize Pusher
    const pusherInstance = new Pusher(PUSHER_CREDENTIALS.APP_KEY, {
      cluster: PUSHER_CREDENTIALS.CLUSTER,
      // authEndpoint: "/pusher/auth", // If using private/presence channels
      // Enable offline mode to handle reconnection automatically
      disableStats: true,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
      retryAfter: 2000, // Retry connection every 2 seconds
    });

    Pusher.logToConsole = true;
    setPusher(pusherInstance);
    // Connection state handlers
 
      pusherInstance.connection.bind("connected", () => {
        console.log("Pusher connected");
        setIsConnected(true);
      });
    

    pusherInstance.connection.bind("disconnected", () => {
      console.log("Pusher disconnected, attempting to reconnect...");
      setIsConnected(false);
    });

    pusherInstance.connection.bind("error", (err) => {
      console.error("Pusher connection error:", err);
    });

    // Clean up on unmount
    return () => {
      pusherInstance.disconnect();
      setPusher(null);
    };
  }, []);

  // Function to subscribe to a channel and event
  const subscribe = useCallback(
    (channelName, eventName, callback) => {
      if (!pusher) return;

      // Subscribe to the channel if not already subscribed
      let channel = channels.current[channelName];
      if (!channel) {
        channel = pusher.subscribe(channelName);
        channels.current[channelName] = channel;
      }

      // Avoid multiple bindings of the same event
      if (!channel._events || !channel._events[eventName]) {
        channel.bind(eventName, callback);
      }
    },
    [pusher]
  );

  // Function to unsubscribe from a channel and event
  const unsubscribe = useCallback(
    (channelName, eventName, callback) => {
      const channel = channels.current[channelName];
      if (channel) {
        channel.unbind(eventName, callback);
        // Check if there are no more bindings and unsubscribe if desired
        if (!Object.keys(channel?._events || {}).length) {
          pusher.unsubscribe(channelName);
          delete channels.current[channelName];
        }
      }
    },
    [pusher]
  );

  return { isConnected, subscribe, unsubscribe };
};

export default usePusher;
