import { Spin } from "antd";
import SingleStrikeTable from "./SingleStrikeTable";

import "./gridStyles.css";
import useStrikeTable from "./hooks/useStrikeTable";

function StickyStrikeTable() {
  const { dark_Theme, gridHeight, isFetching } = useStrikeTable();

  if (isFetching)
    return (
      <div
        style={{ marginTop: 100 }}
        className="w-100 justify-content-center d-flex"
      >
        <Spin size="large" />
      </div>
    );

  return (
    <div
      className={`${
        dark_Theme || false
          ? "pricer-ag-grid__container_dark"
          : "pricer-ag-grid__container"
      }`}
      style={{
        height: gridHeight,
        width: "100%",
      }}
    >
      <SingleStrikeTable />;
    </div>
  );
}

export default StickyStrikeTable;
