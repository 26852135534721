import { Col, Select } from "antd";
import { FormItem } from "./AddOptionHelperComponents";
import { useContext } from "react";
import { GlobalContext } from "contexts/GlobalContext";

function SelectDropdown({ options, value, onChange, label, placeholder, notFoundContent, isLoading }) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  return (
    <Col span={24}>
      <FormItem label={label}>
        <Select
        // loading={isLoading}
          showSearch
          filterOption={(input, option) => {
            const inputSmallLetter = input.toLowerCase();
            const label = option.label.toLowerCase();
            return label.includes(inputSmallLetter);
          }}
          popupClassName={dark_Theme && "dropdown-dark"}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          notFoundContent={notFoundContent}
        />
      </FormItem>
    </Col>
  );
}

export default SelectDropdown;
