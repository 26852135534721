import React from "react";
import {Tooltip} from 'antd';
// Icons
import { CopyOutlined } from "@ant-design/icons";
// Styles
import './HeaderWithIcon.scss';
import { ACTION_BUTTONS_NAME } from "constant/GeneralActionButtons";
import { handleCopyShortHand } from "helpers/generalHelper";

const IS_ACTIVE_KEY = "is_active";
const HeaderWithIcon = (props) => {
  return (
    <div className="header-with-icon__container">
      <span className="displayName">
        {props.displayName}
      </span>
      {/*Show Icon With ToolTip */}
      <Tooltip
        title={ACTION_BUTTONS_NAME.COPY_SHORTHAND.label}
        placement="top"
      >
        <CopyOutlined
          className="copy-shorthand"
          onClick={()=>{ 
            const nodes = []; 
            props.api.forEachNodeAfterFilterAndSort((node) => nodes.push(node.data));  
            const returnActiveRows = nodes.filter(filteredItem => filteredItem[IS_ACTIVE_KEY] === 1);
            let copyStr = "";
            returnActiveRows.forEach((item, index) => copyStr += `${item['copy_shorthand']}\r\n`);  
            const removeLastChar = copyStr.substring(0, copyStr.length - 1);
            handleCopyShortHand(removeLastChar)   
          }}
        />
      </Tooltip>
    </div>
  );
};

export default HeaderWithIcon;
