import api from "./api";

export const OPTION_ROWS_END_POINT = "/option-rows";
const OTHER_INFO_END_POINT = `${OPTION_ROWS_END_POINT}/other-info`;
const TENORS_END_POINT = `${OPTION_ROWS_END_POINT}/tenors`;
const UPDATE_OPTION_END_POINT = `${OPTION_ROWS_END_POINT}/update`;
const DUPLICATE_OPTION_END_POINT = `${OPTION_ROWS_END_POINT}/bulk-duplicate`;
const DELETE_OPTION_END_POINT = `${OPTION_ROWS_END_POINT}/bulk-delete`;
const UPDATE_LIVE_END_POINT = `${OPTION_ROWS_END_POINT}/update`;
const UPDATE_LOCK_END_POINT = `${OPTION_ROWS_END_POINT}/update`;
const TEAM_RUN_END_POINT = "/team-run";
const PUSH_CHECK_END_POINT = `${TEAM_RUN_END_POINT}/push-check`;
const PUSH_TO_TEAM_RUN_END_POINT = `${TEAM_RUN_END_POINT}/push`;
const PUSH_TO_AUDIT_TRAIL_END_POINT = `${OPTION_ROWS_END_POINT}/push-to-audit-trail`;
const OPTION_TYPES_END_POINT = "/option-types";
const PRODUCTS_END_POINT = "/products";
const CONTRACT_STYLE_END_POINT = "/contracts-styles";
const CONTRACT_STYLE_TENORS_END_POINT = `${CONTRACT_STYLE_END_POINT}/tenors`;
const BESPOKE_TENORS_END_POINT = "/bespoke-tenors";
const CONSTRAINTS_END_POINT = "/constraints";

export const fetchProductsExists = async () => {
  return await api.get(OPTION_ROWS_END_POINT);
};

export const fetchOtherInfoData = async (summaryId) => {
  return await api.get(`${OTHER_INFO_END_POINT}?summary_id=${summaryId}`);
};
export const fetchTenors = async (summaryId, legId) => {
  return await api.get(
    `${TENORS_END_POINT}?summary_id=${summaryId}&leg_id=${legId}`
  );
};

export const updateOnEditCell = async (body) => {
  return await api.post(UPDATE_OPTION_END_POINT, { ...body });
};

export const duplicateRow = async (summary_ids) => {
  return await api.post(DUPLICATE_OPTION_END_POINT, { ids: summary_ids });
};

export const deleteRow = async (summary_ids) => {
  return await api.delete(DELETE_OPTION_END_POINT, { ids: summary_ids });
};

export const updateLive = async (body) => {
  return await api.post(UPDATE_LIVE_END_POINT, { ...body });
};

export const updateLock = async (body) => {
  return await api.post(UPDATE_LOCK_END_POINT, { ...body });
};

export const triggerPushOptionToPricer = async (body) => {
  return await api.post(PUSH_CHECK_END_POINT, { ...body });
};

export const onPushOptionClicked = async (
  summaryId,
  tradingCompanies,
  same_team_run_option_id
) => {
  return await api.post(PUSH_TO_TEAM_RUN_END_POINT, {}, {
    summary_id: summaryId,
    trading_companies: tradingCompanies,
    same_team_run_option_id: same_team_run_option_id,
  });
};

export const triggerPushOptionToAuditTrailAPI = async (summaryId, dateTime) => {
  return await api.post(PUSH_TO_AUDIT_TRAIL_END_POINT, {
    summary_id: summaryId,
    date_time: dateTime,
  });
};

export const fetchTypes = async (legsNum) => {
  return await api.get(`${OPTION_TYPES_END_POINT}?number_of_legs=${legsNum}`);
};

export const fetchCommoditiesAPI = async () => {
  return await api.get(PRODUCTS_END_POINT);
};

export const fetchContractStylesAPI = async (productId) => {
  return await api.get(
    `${PRODUCTS_END_POINT}/${productId}${CONTRACT_STYLE_END_POINT}`
  );
};

export const fetchTenorAPI = async (contractStyleSelected) => {
  return await api.get(
    `${CONTRACT_STYLE_TENORS_END_POINT}?contract_style=${contractStyleSelected}`
  );
};

export const fetchBespokeTenorAPI = async (contractStyleSelected) => {
  return await api.get(
    `${BESPOKE_TENORS_END_POINT}?contract_style=${contractStyleSelected}`
  );
};

export const fetchConstraintsAPI = async (typeId, legsNum, contract_style) => {
  return await api.get(
    `${CONSTRAINTS_END_POINT}?option_type_id=${typeId}&number_of_legs=${legsNum}&contract_style=${contract_style}`
  );
};

export const submitAddOptionAPI = async (body) => {
  return await api.post(OPTION_ROWS_END_POINT, {} ,{ ...body });
};
