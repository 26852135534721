import { Col, InputNumber, Row, Select } from "antd";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext } from "react";
export default function VolSurfaceDataFilters({ riskFree, surfaces, value, onChange }) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const handleChange = (value) => {
    onChange(value);
  };
  return (
    <>
      <Row gutter={[25, 25]} style={{ marginBottom: 10 }}>
        <Col span={3}>Risk Free: </Col>
        <Col>
          <InputNumber
            placeholder="0.00"
            className={`${
              dark_Theme
                ? "ant-input-number-dark-disabled"
                : "ant-input-number-light-disabled"
            } w-100 `}
            disabled={true}
            value={riskFree}
            addonAfter={"%"}
          />
        </Col>
      </Row>

      <Row gutter={[25, 25]} style={{ marginBottom: 20 }}>
        <Col span={3}>Surface:</Col>
        <Col>
          <Select
            onChange={handleChange}
            placeholder="Surface"
            className={dark_Theme ? "select-dark" : ""}
            popupClassName={dark_Theme ? "dropdown-dark" : ""}
            value={value || surfaces.at(0)}
            style={{ width: 210 }}
            disabled={false}
            options={surfaces}
            showSearch
            filterOption={(input, option) => {
              const inputSmallLetter = input.toLowerCase();
              const label = option.label.toLowerCase();
              return label.includes(inputSmallLetter);
            }}
          />
        </Col>
      </Row>
    </>
  );
}
