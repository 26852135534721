import React, { createContext, useCallback, useEffect, useMemo, useReducer } from "react";

const IS_SAVE_AND_CREATE_CLICKED = "IS_SAVE_AND_CREATE_CLICKED";
const SET_SELECTED_ROWS_AUDIT_TRAIL = "SET_SELECTED_ROWS_AUDIT_TRAIL";
const SET_TRADE_POPUP_DATA = "SET_TRADE_POPUP_DATA";

const initialState = {
  isSaveAndCreateClicked: false,
  selectedRows: [],
  tradePopup_data: {
    isTradeItemClicked: false,
    data: {},
  },
};

const Reducer = (state, action) => {
  switch (action.type) {
    case IS_SAVE_AND_CREATE_CLICKED:
      return {
        ...state,
        isSaveAndCreateClicked: action.payload,
      };
    
    case SET_TRADE_POPUP_DATA:
      return {
      ...state,
      tradePopup_data: action.payload,
    }

    case SET_SELECTED_ROWS_AUDIT_TRAIL:
      return {
        ...state,
        selectedRows: action.payload,
      };
    default:
      return state;
  }
};

const AuditTrailProvider = ({children, defaultInitialState = {}}) => {
  const [state, dispatch] = useReducer(Reducer, {...initialState, ...defaultInitialState});

  const setOnSaveAndCreateClicked = useCallback((payload) => {
    dispatch({type: IS_SAVE_AND_CREATE_CLICKED, payload});
  }, []);

  const setTradePopupData = useCallback((payload) => {
    dispatch({type: SET_TRADE_POPUP_DATA, payload});
  }, []);

  const setSelectedRowsAuditTrail = useCallback((payload) => {
    dispatch({type: SET_SELECTED_ROWS_AUDIT_TRAIL, payload});
  }, []);


  useEffect(()=>{
    setOnSaveAndCreateClicked(false);
  },[]);

  const contextValue = useMemo(() => ({
    state,
    setOnSaveAndCreateClicked,
    setSelectedRowsAuditTrail,
    setTradePopupData
  }), [state]);

  return (
    <AuditTrailContext.Provider value={contextValue}>
      {children}
    </AuditTrailContext.Provider>
  );
};

export const AuditTrailContext = createContext(initialState);

export default AuditTrailProvider
