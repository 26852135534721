import ReactGA from "react-ga4";

export const ACTIONS = {
  CLICK: "click",
};

export const CATEGORIES = {
  PRICER: "How users get options in their pricer",
};

export const DESCRIPTIONS = {
  ADD_OPTION: "Add option",
  PULL_FROM_TEAM_RUN_MENU: "Pull from Team Run Menu",
  PULL_FROM_TEAM_RUN_ICON: "Pull from Team Run Icon",
  PULL_FROM_AUDIT_TRAIL: "Pull from Audit Trail",
  DUPLICATE: "Duplicate",
};

export const CONTEXTS = {
  PRICER: "Pricer",
  ADD_OPTION: "AddOption",
  TEAM_RUN_ACTION_MENU: "TeamRunActionMenu",
  TEAM_RUN: "TeamRun",
  AUDIT_TRAIL_ACTION_MENU: "AuditTrailActionMenu",
  PRICER_ACTION_MENU: "PricerActionMenu",
};

export const ELEMENTS = {
  ADD_OPTION: "AddOption",
  SUBMIT: "Submit",
  PULL_TO_PRICER: "PullToPricer",
  DUPLICATE: "Duplicate",
};

export const EVENT_NAMES = {
  PRICER_CLICK_ADD_OPTION_BUTTON: "Pricer_Click_AddOption_Button",
  ADD_OPTION_CLICK_SUBMIT_BUTTON: "AddOption_Click_Submit_Button",
  TEAM_RUN_ACTION_MENU_CLICK_PULL_TO_PRICER_MENU_BUTTON:
    "TeamRunActionMenu_Click_PullToPricer_MenuButton",
  TEAM_RUN_CLICK_PULL_TO_PRICER_ICON_BUTTON:
    "TeamRun_Click_PullToPricer_IconButton",
  AUDIT_TRAIL_ACTION_MENU_CLICK_PULL_TO_PRICER_MENU_BUTTON:
    "AuditTrailActionMenu_Click_PullToPricer_MenuButton",
  PRICER_ACTION_MENU_CLICK_DUPLICATE_MENU_BUTTON:
    "PricerActionMenu_Click_Duplicate_MenuButton",
};

export const COMPONENT_NAMES = {
  BUTTON: "Button",
  MENU_BUTTON: "MenuButton",
  ICON_BUTTON: "IconButton",
};

export const sendEvent = ({
  category,
  action,
  description,
  eventName,
  context,
  element,
  component,
}) => {
  var params = {};
  if (category) params.category = category;
  if (action) params.action = action;
  if (description) params.description = description;
  if (eventName) params.eventName = eventName;
  if (context) params.context = context;
  if (element) params.element = element;
  if (component) params.component = component;

  ReactGA.event(eventName, params);
};
