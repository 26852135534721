import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Menu, Dropdown, Space, Tooltip, Modal } from "antd";
import {
  MenuOutlined,
  CopyOutlined,
  ImportOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
//import helpers
import {
  ACTION_TYPE_DETAILED_VIEW,
  AUDIT_TRAIL_BULK_ACTIONS,
  DETAILS_MENU_FORMATTED,
} from "constant/AuditTrail/AuditTrail";

import AdditionalDataModal from "./AdditionalData";
import EditTradeDetails from "components/EditTradeDetails/EditTradeDetails";

//import Style
import "styles/PricerAuditAgGridLayout.scss";
import { GlobalContext } from "contexts/GlobalContext";
import {
  extractCopyShortHandForSummaryRows,
  isSummaryLevel,
} from "helpers/generalHelper";
import { ACTION_BUTTONS_NAME } from "constant/GeneralActionButtons";
import { MarketAnalysisContext } from "contexts/MarketAnalysisContext";
import { handleCopyShortHand, handleCopyTradeDetails, toast } from "helpers/generalHelper";
import { deleteAuditTrailRow } from "api/auditTrailApi";
import { createPortal } from "react-dom";
import {
  ACTIONS,
  CATEGORIES,
  COMPONENT_NAMES,
  CONTEXTS,
  DESCRIPTIONS,
  ELEMENTS,
  EVENT_NAMES,
  sendEvent,
} from "helpers/googleAnalyticsHelper";
import { handlePullToPricerClick } from "helpers/auditTrailHelper";
import { AuditTrailContext } from "contexts/AuditTrailContext";

const AuditTrailActionButtons = (props) => {
  const {
    globalState: { auditTrailState, dark_Theme },
  } = useContext(GlobalContext);
  const params = useParams();
  const location = useLocation();

  const { MarketAnalysisState } = useContext(MarketAnalysisContext);
  const { setTradePopupData } = useContext(AuditTrailContext);
  const [detailedMenu, setDetailedMenu] = useState();
  const navigate = useNavigate();
  const selectedRows = location.pathname.includes("market-analysis")
    ? MarketAnalysisState.selectedRows
    : auditTrailState.selectedRows;
  const [menuItemClicked, setMenuItemClicked] = useState({
    [ACTION_BUTTONS_NAME.COPY_SHORTHAND.key]: false,
    [ACTION_BUTTONS_NAME.COPY_TRADE_DETAILS.key]: false,
    [ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.key]: false,
    [ACTION_BUTTONS_NAME.PULL_TO_PRICER.key]: false,
    [ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key]: false,
    [ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key]: false,
    [ACTION_BUTTONS_NAME.EDIT_TRADE_DETAILS.key]: false,
    [ACTION_BUTTONS_NAME.VOID]: false,
  });
  const [showVoidModal, setShowVoidModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    let newMenu = [];
    let detailedMenuData = DETAILS_MENU_FORMATTED;

    if (params?.id) {
      detailedMenuData.map((menu) => {
        if (menu.key !== "detailed_audit_trail") {
          newMenu.push(menu);
        }
        return menu;
      });
    } else {
      newMenu = detailedMenuData;
    }
    // it was readding the menu items on every render and it was causing the menu to be duplicated
    if (!props.data?.can_be_voided) {
      newMenu = newMenu.filter(
        (menu) => menu.key !== ACTION_BUTTONS_NAME.VOID.key
      );
    }

    setDetailedMenu(newMenu);
  }, [props.data, params]);

  //Effect To Handle CopyShortHand Or Pull To Pricer From MenuItems
  useEffect(() => {
    const data = props.data ? props.data : props.node.aggData;
    if (menuItemClicked[ACTION_BUTTONS_NAME.COPY_SHORTHAND.key]) {
      return extractCopyShortHandForSummaryRows(
        props,
        true,
        false,
        params?.id ? true : false
      );
    }

    if (menuItemClicked[ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.key]) {
      return extractCopyShortHandForSummaryRows(
        props,
        true,
        true,
        params?.id ? true : false
      );
    }

    if (menuItemClicked[ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key]) {
      return extractCopyShortHandForSummaryRows(
        props,
        false,
        false,
        params?.id ? true : false
      );
    }

    if (menuItemClicked[ACTION_BUTTONS_NAME.PULL_TO_PRICER.key]) {
      sendEvent({
        category: CATEGORIES.PRICER,
        description: DESCRIPTIONS.PULL_FROM_AUDIT_TRAIL,
        context: CONTEXTS.AUDIT_TRAIL_ACTION_MENU,
        action: ACTIONS.CLICK,
        element: ELEMENTS.PULL_TO_PRICER,
        component: COMPONENT_NAMES.MENU_BUTTON,
        eventName:
          EVENT_NAMES.AUDIT_TRAIL_ACTION_MENU_CLICK_PULL_TO_PRICER_MENU_BUTTON,
      });
      return handlePullToPricerClick(selectedRows);
    }

      if (menuItemClicked[ACTION_BUTTONS_NAME.TRADE.key]) {
        let teamRun_data = {
          isTradeItemClicked: true,
          data: props.data,
        };
        setTradePopupData(teamRun_data);
        return;
      }

    if (menuItemClicked[ACTION_BUTTONS_NAME.COPY_TRADE_DETAILS.key]) {
      return handleCopyTradeDetails(data?.trade_details);
    }

    if (menuItemClicked[ACTION_BUTTONS_NAME.VOID.key]) {
      return setShowVoidModal(true);
    }

    if (menuItemClicked[ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key]) {
      return navigate(`/audit-trail/${data?.["team_run_option_id"]}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemClicked]);

  const onVoidConfirmed = () => {
    const data = props.data ? props.data : props.node.aggData;
    const auditTrailId = data.audit_trail_id;
    setConfirmLoading(true);
    deleteAuditTrailRow(auditTrailId).then((res) => {
      // refresh data source and keep page number
      refreshDataSource(props.api);
      toast(res?.data?.message, "success");
      setConfirmLoading(false);
      setShowVoidModal(false);
    });
  };

  const refreshDataSource = (gridOptions) => {
    const currentPage = props.api.paginationGetCurrentPage();
    // Refresh the server-side data source
    props.api.refreshServerSideStore();
    // Navigate back to the same page after refresh
    props.api.paginationGoToPage(currentPage);
  };

  const handleMenuItemClicked = useCallback((key, value) => {
    setMenuItemClicked({ ...menuItemClicked, [key]: value });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSelectedItem = useMemo(() => {
    if (menuItemClicked[ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key]) {
      return (
        <AdditionalDataModal
          isOpen={menuItemClicked[ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key]}
          handleMenuItemClicked={handleMenuItemClicked}
          rowSelectedInfo={props?.data ? props.data : props.node.aggData}
        />
      );
    }

    if (menuItemClicked[ACTION_BUTTONS_NAME.EDIT_TRADE_DETAILS.key]) {
      return (
        <EditTradeDetails
          isEditMode
          isOpen={menuItemClicked[ACTION_BUTTONS_NAME.EDIT_TRADE_DETAILS.key]}
          handleMenuItemClicked={handleMenuItemClicked}
          rowSelectedInfo={props?.data ? props.data : props.node.aggData}
        />
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemClicked]);

  const showCopyShortHandAndPullPricer = useMemo(() => {
    if (!params.id) return;
    if (props.data.action !== ACTION_TYPE_DETAILED_VIEW.ARCHIVE) return;

    return (
      <>
        <Tooltip title={ACTION_BUTTONS_NAME.COPY_SHORTHAND.label}>
          <CopyOutlined
            className="button-icon"
            onClick={() => handleCopyShortHand(props.data?.copy_shorthand)}
          />
        </Tooltip>
        <Tooltip title={ACTION_BUTTONS_NAME.PULL_TO_PRICER.label}>
          <ImportOutlined
            className="rotate-import--icon button-icon"
            onClick={() => handlePullToPricerClick(props.data?.summary_id)}
          />
        </Tooltip>
      </>
    );
  }, [props.data, params?.id]);

  const showCopyDetailsAndEditTradeDetails = useMemo(() => {
    if (!params.id) return;
    if (props.data.action !== ACTION_TYPE_DETAILED_VIEW.TRADE) return;
    return (
      <>
        <Tooltip title={ACTION_BUTTONS_NAME.COPY_TRADE_DETAILS.label}>
          <SnippetsOutlined
            onClick={() =>
              handleCopyTradeDetails(props.data?.copy_trade_details)
            }
            className="button-icon"
          />
        </Tooltip>
      </>
    );
  }, [props.data, params?.id]);

  if (!isSummaryLevel(props)) return <></>;

  return (
    <>
      <div className="action-buttons__container action-button_container">
        {/* Show Modal */}
        {showSelectedItem}
        {/* End Of Show Modal Func */}
        {/* Action Buttons With Tooltip */}
        {showCopyShortHandAndPullPricer}
        {showCopyDetailsAndEditTradeDetails}

        <Dropdown
          className={`action-buttons__dropdown button-icon ${
            !dark_Theme && "button-light-theme"
          }`}
          key="action-buttons__menu-items"
          trigger={["click"]}
          overlay={
            <Menu
              onClick={({ key }) => handleMenuItemClicked(key, true)}
              key="action-buttons__menu-items_MENU"
              items={
                selectedRows.length < 2
                  ? detailedMenu
                  : AUDIT_TRAIL_BULK_ACTIONS
              }
              theme={dark_Theme ? "dark" : "light"}
            />
          }
        >
          <Space>
            <MenuOutlined />
          </Space>
        </Dropdown>
        {createPortal(
          <Modal
            className={`${dark_Theme && "ant-modal-dark content_dark"}`}
            title="Void Confirmation"
            style={{
              top: 20,
            }}
            confirmLoading={confirmLoading}
            open={showVoidModal}
            okText="Void"
            onOk={() => onVoidConfirmed()}
            onCancel={() => setShowVoidModal(false)}
            centered
          >
            <p>
              Are you sure you want to void this item? This action cannot be
              undone.
            </p>
          </Modal>,
          document.body
        )}
        {/* End Of Action Buttons With Tooltip */}
      </div>
    </>
  );
};

export default AuditTrailActionButtons;
