import { getControlTableData, getGraphsData } from "api/volSurfaceApi";
import { VolSurfaceContext } from "contexts/VolSurfaceContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import useVolSurfaceWebSocket from "hooks/useVolSurfaceWebSocket";
import { useContext, useEffect } from "react";

function useControlTable({ start_date, end_date }) {
  const { setVolSurfaceData, setVolSurfaceBtnLoading } =
    useContext(VolSurfaceContext);

  const {
    setVolSurfaceData: setRowsData,
    selectedFilters,
    setVolCalcTable,
    setRiskFree,
  } = useVolSurfaceControlTable();

  function getTableDate() {
    if (!selectedFilters?.product_id) return;
    setVolSurfaceBtnLoading(true);
    getControlTableData({
      product_contract_style_id: selectedFilters?.product_id,
      risk_free: selectedFilters?.risk_free || 0,
      comparison_surface_id: selectedFilters?.comparison_surface_id,
      start_date: start_date,
      end_date: end_date,
    })
      .then((res) => {
        const data = res.data.data;
        setRowsData(data.main_control_table);
        setVolCalcTable(data.vol_calc_table);
        if (+selectedFilters?.risk_free !== +data.risk_free) {
          setRiskFree(data.risk_free);
        }
      })
      .finally(() => {
        setVolSurfaceBtnLoading(false);
      });
  }

  //Get Control Table Data
  useEffect(() => {
    if (!selectedFilters?.product_id) return;
    getTableDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFilters?.comparison_surface_id,
    selectedFilters?.product_id,
    selectedFilters?.risk_free,
    start_date,
    end_date,
  ]);

  //websocket call for control table
  useVolSurfaceWebSocket(selectedFilters, () => {
    if (!selectedFilters?.product_id) return;
    getTableDate();
    getGraphsData({
      product_contract_style_id: selectedFilters?.product_id,
      comparison_surface_id: selectedFilters?.comparison_surface_id,
      start_date: start_date,
      end_date: end_date,
    }).then((res) => {
      const response = res.data.data;

      setVolSurfaceData(response);
    });
  });
}

export default useControlTable;
