import { Button } from "antd";
import { EDIT_TRADE_DETAILS_LABELS } from "constant/AuditTrail/AuditTrail";
import { ACTION_BUTTONS_NAME } from "constant/GeneralActionButtons";

export const EditTradeFooter = (
    props,
    triggerPostEditApi,
    isRequestActive,
    isCreateLoading,
    isSaveAndCreateButtonDisabled,
    onAddTrader
) => [
  <Button
    key={ACTION_BUTTONS_NAME.EDIT_TRADE_DETAILS.key}
    onClick={() => props.handleMenuItemClicked(false)}
  >
    {EDIT_TRADE_DETAILS_LABELS.CANCEL}
  </Button>,
  <Button
    loading={isCreateLoading}
    disabled={isSaveAndCreateButtonDisabled || isRequestActive}
    key={EDIT_TRADE_DETAILS_LABELS.SAVE_SEND_AND_COPY}
    onClick={triggerPostEditApi}
    type="primary"
  >
    {EDIT_TRADE_DETAILS_LABELS.SAVE_SEND_AND_COPY}
  </Button>,
  <Button
    key={EDIT_TRADE_DETAILS_LABELS.ADD_TRADER}
    onClick={() => {
    onAddTrader();
    }}
    type="primary"
  >
    {EDIT_TRADE_DETAILS_LABELS.ADD_TRADER}
  </Button>,
  <Button
    key={EDIT_TRADE_DETAILS_LABELS.FINISH}
    onClick={() => {
      props.handleMenuItemClicked(false);
    }}
    type="primary"
  >
    {EDIT_TRADE_DETAILS_LABELS.FINISH}
  </Button>,
];