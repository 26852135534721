import api from "./api";

const MARKET_ANALYSIS_END_POINT = "market-analysis";
const MARKET_ANALYSIS_GRAPH_END_POINT = `${MARKET_ANALYSIS_END_POINT}/graphs`;
const EXPORT_MARKET_ANALYSIS_END_POINT = `${MARKET_ANALYSIS_END_POINT}/export`;
const FILTERS_GRAPH_END_POINT = `${MARKET_ANALYSIS_GRAPH_END_POINT}/filters`;
const TABLE_FILTER_END_POINT = `${MARKET_ANALYSIS_END_POINT}/get-table-filters`;

export const exportMarketAnalysis = async (params) => {
  return await api.get(EXPORT_MARKET_ANALYSIS_END_POINT, { ...params });
};

export const fetchMarketAnalysisGraph = async (param) => {
  return await api.get(MARKET_ANALYSIS_GRAPH_END_POINT, { ...param });
};

export const fetchMarketAnalysisTableData = async (queryParams) => {
  return await api.get(MARKET_ANALYSIS_END_POINT, { ...queryParams });
};

export const fetchMarketAnalysisFilters = async () => {
  return await api.get(FILTERS_GRAPH_END_POINT);
};

export const fetchMarketAnalysisTableFilters = async () => {
  return await api.get(TABLE_FILTER_END_POINT);
};
