import { Col } from "antd";
import HighchartsReact from "highcharts-react-official";

export default function VolSurfaceOptionsGraph({
  volSurfaceOptions,
  Highcharts,
}) {
  return (
    <Col className="vol-surface-graph" xxl={24} >
        {volSurfaceOptions?.["graphs"].map((graphOptions, index) => {
          if (index !== 1) {
            return (
              <Col
                span={24}
                style={{ margin: "0px 5px 20px 5px" }}
                key={JSON.stringify(graphOptions)}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...graphOptions,
                    accessibility: {
                      enabled: false,
                    },
                  }}
                />
              </Col>
            );
          } else {
            return null;
          }
        })}
    
    </Col>
  );
}
