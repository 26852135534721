import {
  ContainerOutlined,
  ImportOutlined,
  AuditOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ActionButtons from "components/AuditTrail/AuditTrailActionButtons";
import CheckBoxCell from "components/AuditTrail/CheckBoxCell";
import { ACTION_BUTTONS_NAME } from "../GeneralActionButtons";
import { cellStyle, formatNumber } from "helpers/generalHelper";

export const TRADE_FILTER_LABELS = {
  ALL: "All",
  TRADED: "Traded",
};

export const FIELD_NAMES = {
  UIN: "uin",
  SUMMARY_ID: "summary_id",
  DATE_TIME: "date_time",
  ACTION: "action",
  ACTIONS: "actions",
  SUB_ACTION: "sub_action",
  TRADER: "trader",
  BROKER: "broker",
  COMMODITY: "commodity",
  CONTRACT: "contract",
  STYLE: "style",
  TENOR: "tenor",
  UNDERLYING: "underlying",
  LIVE: "live",
  STRIKE: "strike",
  TYPE: "type",
  RATIO: "ratio",
  QUANTITY: "quantity",
  VOLATILITY: "volatility",
  PREMIUM: "premium",
  EXCHANGE: "exchange",
  QUANTITY_T: "quantity_t",
  DELTA_QUANTITY_T: "delta_quantity_t",
  MONEYNESS: "moneyness",
  DELTA_QUANTITY: "delta_quantity",
  SUMMARY_REFERENCE_ID: "summary_reference_id",
  AUDIT_TRAIL_ID: "audit_trail_id",
  EXPIRY_DATE: "expiry_date",
  TRADING_COMPANY: "trading_company",
  TRADE_ID: "trade_id",
};



export const MARKET_ANALYSIS_COLUMNS = [
  {
    headerName: "Select",
    field: "select",
    maxWidth: 80,
    checkboxSelection: (params) => params.node.level === 0 && true,
  },
  { headerName: "UIN", field: FIELD_NAMES.UIN, sortable: true },
  { headerName: "id", field: FIELD_NAMES.ID, hide: true },
  { headerName: "Date/Time", field: FIELD_NAMES.DATE_TIME },
  { headerName: "Last Action", field: FIELD_NAMES.ACTION },
  { headerName: "Sub-Action", field: FIELD_NAMES.SUB_ACTION },

  { headerName: "Broker", field: FIELD_NAMES.BROKER },
  {
    headerName: "Commodity",
    field: FIELD_NAMES.COMMODITY,
    sortable: true,
    minWidth: 125,
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      values: (params) => {
        const values = params?.colDef?.filterItems?.commodities;
        params.success(values);
      },
    },
  },
  {
    headerName: "Contract",
    field: FIELD_NAMES.CONTRACT,
    sortable: true,
    filter: "agSetColumnFilter",
    minWidth: 100,

    filterParams: {
      suppressMiniFilter: true,
      values: (params) => {
       const values = params?.colDef?.filterItems?.contracts;
        params.success(values);
      },
    },
  },
  {
    headerName: "Style",
    field: FIELD_NAMES.STYLE,
    sortable: true,
    filter: "agSetColumnFilter",
    minWidth: 100,
    filterParams: {
      suppressMiniFilter: true,
      values: (params) => {
          const values = params?.colDef?.filterItems?.styles;
        params.success(values);
      },
    },
  },
  {
    headerName: "Tenor",
    field: FIELD_NAMES.TENOR,
    sortable: true,
    filter: "agSetColumnFilter",
    minWidth: 100,
    filterParams: {
      suppressMiniFilter: true,
      values: (params) => {
          const values = params?.colDef?.filterItems?.tenors;
        params.success(values);
      },
    },
  },
  { headerName: "Expiry Date", field: FIELD_NAMES.EXPIRY_DATE },
  {
    headerName: "U/L",
    field: FIELD_NAMES.UNDERLYING,
    sortable: true,
    filter: "agSetColumnFilter",
    minWidth: 100,
    filterParams: {
      suppressMiniFilter: true,
      values: (params) => {
         const values = params?.colDef?.filterItems?.underlyings;
        params.success(values);
      },
    },
  },
  {
    headerName: "Live",
    field: FIELD_NAMES.LIVE,
    cellRendererFramework: CheckBoxCell,
  },
  {
    headerName: "Strike",
    field: FIELD_NAMES.STRIKE,
    sortable: true,
    minWidth: 100,
    filter: "agSetColumnFilter",
    filterParams: {
      suppressMiniFilter: true,
      values: (params) => {
         const values = params?.colDef?.filterItems?.strikes;
        params.success(values);
      },
    },
  },
  {
    headerName: "Type",
    field: FIELD_NAMES.TYPE,
    showRowGroup: true,
    minWidth: 125,
    cellClass: (params) =>
      params.data?.legs_count === 1 ? "dropdownHidden" : "",
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: (params) => {
        const node = params.node;
        const rowData = node.data;
        return rowData.type;
      },
    },
    sortable: true,
  },
  { headerName: "Ratio", field: FIELD_NAMES.RATIO },
  {
    headerName: "Qty",
    field: FIELD_NAMES.QUANTITY,
  },
  { headerName: "%", field: FIELD_NAMES.VOLATILITY },
  {
    headerName: "Premium",
    field: FIELD_NAMES.PREMIUM,
  },
  { headerName: "Trader Company", field: FIELD_NAMES.TRADING_COMPANY },
  { headerName: "Trader", field: FIELD_NAMES.TRADER },
  {
    headerName: "Exchange (tr)",
    field: FIELD_NAMES.EXCHANGE,
  },
  {
    headerName: "Delta Qty (tr)",
    field: FIELD_NAMES.DELTA_QUANTITY_T,
  },
  { headerName: "Moneyness", field: FIELD_NAMES.MONEYNESS },
  {
    headerName: "Actions",
    field: FIELD_NAMES.ACTIONS,
    cellRenderer: ActionButtons,
    minWidth: 85,
  },
  {
    field: FIELD_NAMES.AUDIT_TRAIL_ID,
    rowGroup: true,
    hide: true,
    cellRendererParams: {
      suppressCount: true,
    },
  },
].map((item) => {
  return {
    minWidth: 80,
    ...item,
    resizable: true,
    menuTabs:
      item.field === FIELD_NAMES.COMMODITY ||
      item.field === FIELD_NAMES.CONTRACT ||
      item.field === FIELD_NAMES.STYLE ||
      item.field === FIELD_NAMES.TENOR ||
      item.field === FIELD_NAMES.STRIKE ||
      item.field === FIELD_NAMES.UNDERLYING
        ? ["filterMenuTab"]
        : [],
    suppressHeaderFilterButton: true,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButtons: true,
    cellStyle: (params) => cellStyle(params),
    valueFormatter: (params) => {
      const field = params.colDef.field;

      if (
        field === FIELD_NAMES.RATIO ||
        field === FIELD_NAMES.UNDERLYING ||
        field === FIELD_NAMES.STRIKE ||
        field === FIELD_NAMES.QUANTITY ||
        field === FIELD_NAMES.PREMIUM ||
        field === FIELD_NAMES.MONEYNESS ||
        field === FIELD_NAMES.DELTA_QUANTITY ||
        field === FIELD_NAMES.DELTA_QUANTITY_T ||
        field === FIELD_NAMES.VOLATILITY
      ) {
        if (typeof params?.value === "object") {

          return params?.value?.value === null ? "" : formatNumber(params?.value?.value);
        } else {
          return params?.value === null ? "" : formatNumber(params?.value);
        }
      }
      if (typeof params?.value === "object") {
        return params?.value?.value;
      }
      return params?.value;
    },
  };
});

export const AUDIT_TRAIL_COLUMNS = [
  {
    headerName: "Select",
    field: "select",
    maxWidth: 80,
    checkboxSelection: (params) => params.node.level === 0 && true,
  },
  { headerName: "UIN", field: FIELD_NAMES.UIN, sortable: true },
  { headerName: "id", field: FIELD_NAMES.ID, hide: true },
  { headerName: "Date/Time", field: FIELD_NAMES.DATE_TIME },
  { headerName: "Last Action", field: FIELD_NAMES.ACTION },
  { headerName: "Sub-Action", field: FIELD_NAMES.SUB_ACTION },

  { headerName: "Broker", field: FIELD_NAMES.BROKER },
  { headerName: "Commodity", field: FIELD_NAMES.COMMODITY, sortable: true },
  { headerName: "Contract", field: FIELD_NAMES.CONTRACT, sortable: true },
  { headerName: "Style", field: FIELD_NAMES.STYLE, sortable: true },
  { headerName: "Tenor", field: FIELD_NAMES.TENOR, sortable: true },
  { headerName: "Expiry Date", field: FIELD_NAMES.EXPIRY_DATE },
  { headerName: "U/L", field: FIELD_NAMES.UNDERLYING, sortable: true },
  {
    headerName: "Live",
    field: FIELD_NAMES.LIVE,
    cellRendererFramework: CheckBoxCell,
  },
  { headerName: "Strike", field: FIELD_NAMES.STRIKE, sortable: true },
  {
    headerName: "Type",
    field: FIELD_NAMES.TYPE,
    showRowGroup: true,
    minWidth: 125,
    cellClass: (params) =>
      params.data?.legs_count === 1 ? "dropdownHidden" : "",
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: (params) => {
        const node = params.node;
        const rowData = node.data;
        return rowData.type;
      },
    },
    sortable: true,
  },
  { headerName: "Ratio", field: FIELD_NAMES.RATIO },
  {
    headerName: "Qty",
    field: FIELD_NAMES.QUANTITY,
  },
  { headerName: "%", field: FIELD_NAMES.VOLATILITY },
  {
    headerName: "Premium",
    field: FIELD_NAMES.PREMIUM,
  },
  { headerName: "Trade ID", field: FIELD_NAMES.TRADE_ID },
  { headerName: "Trader Company", field: FIELD_NAMES.TRADING_COMPANY },
  { headerName: "Trader", field: FIELD_NAMES.TRADER },
  {
    headerName: "Exchange (tr)",
    field: FIELD_NAMES.EXCHANGE,
  },
  {
    headerName: "Delta Qty (tr)",
    field: FIELD_NAMES.DELTA_QUANTITY_T,
  },
  { headerName: "Moneyness", field: FIELD_NAMES.MONEYNESS },
  {
    headerName: "Actions",
    field: FIELD_NAMES.ACTIONS,
    cellRenderer: ActionButtons,
    minWidth: 85,
  },
  {
    field: FIELD_NAMES.AUDIT_TRAIL_ID,
    rowGroup: true,
    hide: true,
    cellRendererParams: {
      suppressCount: true,
    },
  },
].map((item) => ({
  minWidth: 80,
  ...item,
  resizable: true,
  cellStyle: (params) => cellStyle(params),
  valueFormatter: (params) => {
    const field = params.colDef.field;

    if (
      field === FIELD_NAMES.RATIO ||
      field === FIELD_NAMES.UNDERLYING ||
      field === FIELD_NAMES.STRIKE ||
      field === FIELD_NAMES.QUANTITY ||
      field === FIELD_NAMES.PREMIUM ||
      field === FIELD_NAMES.MONEYNESS ||
      field === FIELD_NAMES.DELTA_QUANTITY ||
      field === FIELD_NAMES.DELTA_QUANTITY_T ||
      field === FIELD_NAMES.VOLATILITY
    ) {
      if (typeof params?.value === "object") {

          return params?.value?.value === null? '' : formatNumber(params?.value?.value);
      } else {
        return params?.value === null? '' : formatNumber(params?.value);
      }
    }
    if (typeof params?.value === "object") {
      return params?.value?.value;
    }
    return params?.value;
  },
}));

export const DETAILS_MENU_FORMATTED = [
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND.label,
    icon: ACTION_BUTTONS_NAME.COPY_SHORTHAND.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.label,
    icon: ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.label,
    icon: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.PULL_TO_PRICER.key,
    label: ACTION_BUTTONS_NAME.PULL_TO_PRICER.label,
    icon: <ImportOutlined />,
  },
  {
    key: ACTION_BUTTONS_NAME.TRADE.key,
    label: ACTION_BUTTONS_NAME.TRADE.label,
    icon: <EditOutlined />,
  },
  {
    key: ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key,
    label: ACTION_BUTTONS_NAME.ADDITIONAL_DATA.label,
    icon: <ContainerOutlined />,
  },
  {
    key: ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key,
    label: ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.label,
    icon: <AuditOutlined />,
  },
  {
    key: ACTION_BUTTONS_NAME.VOID.key,
    label: ACTION_BUTTONS_NAME.VOID.label,
    icon: <DeleteOutlined />,
  },
];

export const AUDIT_TRAIL_BULK_ACTIONS = DETAILS_MENU_FORMATTED.filter(
  (item) =>
    item.key === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key ||
    item.key === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key ||
    item.key === ACTION_BUTTONS_NAME.PULL_TO_PRICER.key
);

export const ADDITIONAL_DATA_LABELS = {
  TENOR_EXPIRY: { label: "Tenor Expiry", key: "tenor_expiry" },
  DELTA: { label: "Delta %", key: "delta" },
  GAMMA_QTY: { label: "Gamma Qty", key: "gamma_quantity" },
  VEGA: { label: "Vega", key: "vega" },
  THETA: { label: "Theta", key: "theta" },
  RHO: { label: "Rho", key: "rho" },
};

export const ADDITIONAL_DATA_QUANTITIES = {
  AVERAGE: { label: "Average", isDisabled: true, key: "average" },
  COST_OF_CARRY: {
    label: "Cost of Carry",
    isDisabled: true,
    key: "cost_of_carry",
  },
  RF: { label: "Rf", isDisabled: true, key: "risk_free" },
  MODEL: { label: "Model", isDisabled: true, key: "model" },
  TOTAL_QUANTITY: {
    label: "Total Quantity",
    isDisabled: true,
    key: "total_quantity",
  },
  TOTAL_PREMIUM: {
    label: "Total Premium",
    isDisabled: true,
    key: "total_premium",
  },
  SHORTHAND: { label: "Shorthand", isDisabled: true, key: "shorthand" },
};

export const EDIT_TRADE_DETAILS_LABELS = {
  TRADE_OPTION: "Trade option",
  BUY: "Buy",
  SELL: "Sell",
  COPY_TRADE_DETAILS: "Copy trade details",
  SAVE_SEND_AND_COPY: "Save & Send & Copy Trade Details",
  FINISH: "Finish",
  CANCEL: "Cancel",
  ADD_TRADER: "Add Trader",
};

export const ACTION_TYPE_DETAILED_VIEW = {
  TRADE: "Trade",
  ARCHIVE: "Archive",
};

export const STATE_KEYS_NAME = {
  TRADER: "trader",
  COMPANY_TRADER: "trading_company",
  EXCHANGE_ID: "exchangeId",
  QTY_VALUE: "qtyValue",
  SYMBOL: "symbol",
  EDIT_TRADE_TITLE: "title",
  QTY_VALUES: "quantity_t",
  STEP_SIZE: "step_size",
  EXCHANGE: "exchange",
};

export const API_END_POINT_NAME = {
  TEAM_RUN: "team-run",
  PRICER: "option-rows",
  AUDIT_TRAIL: "audit-trails",
};

export const AUDIT_TRAIL_SELECTED_FILTER = "auditTrail_selectedFilter";
