import { Col, InputNumber, Row } from "antd";
import { RISK_FREE_KEY } from "constant/VolSurface";
import { storeInLocalStorage } from "helpers/Pricer/Table";

export default function RiskFree({
  dark_Theme,
  riskFree,
  setRiskFree,
  disabledCondition,
}) {
  function handleOnChange(value) {
    storeInLocalStorage(RISK_FREE_KEY, JSON.stringify(value));
    setRiskFree(value);
  }
  return (
    <Col span={24}>
      <Row gutter={[25, 25]}>
        <Col span={3}>Risk Free: </Col>
        <Col>
          <InputNumber
            placeholder="0.00"
            className={`${
              disabledCondition
                ? dark_Theme
                  ? "ant-input-number-dark-disabled"
                  : "ant-input-number-light-disabled"
                : dark_Theme
                ? "ant-input-number-dark"
                : "ant-input-number-light"
            } w-100 `}
            disabled={disabledCondition}
            value={riskFree }
            onChange={(value) => handleOnChange(value)}
            addonAfter={"%"}
          />
        </Col>
      </Row>
    </Col>
  );
}
