import { Button } from "antd";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div  className="d-flex justify-content-center align-items-center w-100 h-100 ">
      
      <div className="card w-50 p-15 d-flex flex-column justify-content-center align-items-center">
        <h1>{"Something went wrong"}</h1>
        <p className="m-0 p-0 fw-medium fs-5 text-center">{error.message}</p>
        {process.env.REACT_APP_NODE_ENV === "development" && (
          <pre>{error.stack}</pre>
        )}
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    </div>
  );
}

export default ErrorFallback;
