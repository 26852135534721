import React, { useContext } from "react";
import { InputNumber, Select, Row, Col } from "antd";
import { STATE_KEYS_NAME } from "constant/AuditTrail/AuditTrail";
import { GlobalContext } from "contexts/GlobalContext";
import { BACKEND_KEYS_NAME } from "constant/EditTradeDetails/EditTradeDetails";
import CompanyTraders from "./CompanysTrader";
import TraderDropdown from "./TraderDropdown";

const API_KEYS = {
  TRADE_PLACEHOLDER: "trader_placeholder",
  COMPANY_TRADER_PLACEHOLDER: "trading_company_placeholder",
  EXCHANGE_PLACEHOLDER: "exchange_placeholder",
  QUANTIZE_PLACEHOLDER: "quantity_t_placeholder",
  SELL_OR_BUY: "sell_or_buy",
};

const ShowFirstRowDetail = ({
  exChangeData,
  apiData,
  setEditQuantityValue,
  handleExchangeChange,
  editQuantityValue,
  handleTraderChange,
  handleCompanyTraderChange,
  setIsRequestActive,
  tradingCompanyId,
  setTradingCompanyId,
  setTraderId,
  ...item
}) => {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  return (
    <Row gutter={20}>
      <Col span={16}>
        <Row gutter={20}>
          <Col span={12}></Col>
          <Col span={4}></Col>
          <Col span={4}>
            <CompanyTraders
              onChange={handleCompanyTraderChange}
              setTradingCompanyId={setTradingCompanyId}
              defaultValue={apiData?.trading_company}
              placeholder={apiData?.[API_KEYS.COMPANY_TRADER_PLACEHOLDER]}
              value={item[BACKEND_KEYS_NAME.COMPANY_TRADER]}
            />
          </Col>
          <Col span={4}>
            <TraderDropdown
              tradingCompanyId={tradingCompanyId}
              apiData={apiData}
              item={item}
              onChange={handleTraderChange}
              setTraderId={setTraderId}
            />
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={20}>
          <Col span={3}>
            {
              <span className="sell-or-buy--label">
                {apiData ? apiData[API_KEYS.SELL_OR_BUY] : "buy"}
              </span>
            }
          </Col>
          <Col span={5}>
            <Select
              placeholder={apiData?.[API_KEYS.EXCHANGE_PLACEHOLDER]}
              key={item[STATE_KEYS_NAME.EXCHANGE]}
              popupClassName={dark_Theme && "dropdown-dark"}
              className="audit-drop-down"
              onChange={(value) =>
                handleExchangeChange(BACKEND_KEYS_NAME.EXCHANGE_ID, value)
              }
              options={exChangeData}
              value={item.exchange}
              style={{ minWidth: 95, maxWidth: 95 }}
            />
          </Col>
          <Col span={10}>
            <InputNumber
              onStep={() => setIsRequestActive(true)}
              onInput={() => setIsRequestActive(true)}
              onFocus={() => setIsRequestActive(true)}
              onBlur={() => setIsRequestActive(false)}
              type="number"
              placeholder={apiData?.[API_KEYS.QUANTIZE_PLACEHOLDER]}
              disabled={
                apiData[BACKEND_KEYS_NAME.QUANTITY_T]?.[
                  BACKEND_KEYS_NAME.IS_DISABLED
                ] || false
              }
              value={
                editQuantityValue !== undefined
                  ? editQuantityValue
                  : apiData[BACKEND_KEYS_NAME.QUANTITY_T]?.[
                      BACKEND_KEYS_NAME.VALUE
                    ]
              }
              step={
                apiData[BACKEND_KEYS_NAME.QUANTITY_T][
                  BACKEND_KEYS_NAME.STEP_SIZE
                ] || 0.005
              }
              onChange={(value) => setEditQuantityValue(value)}
              addonAfter={
                apiData[BACKEND_KEYS_NAME.QUANTITY_T]?.[
                  BACKEND_KEYS_NAME.SYMBOL
                ]
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ShowFirstRowDetail;
