/* eslint-disable array-callback-return */
import { formatNumber } from "./generalHelper";

export const editableBasedOnMetricForTenorCol = (params) =>
  params.data?.is_disabled === 0 &&

  (params.data?.metric === "volatility" || params?.data?.metric === "spread");

export function isCellEditable(event) {
  const field = event.data?.metric;
  const headerName = event.colDef.headerName;
  if (

    event?.data?.is_disabled === 0 &&
    (field === "volatility" || (field === "spread" && headerName !== "0.5"))
  ) {
    return true;
  }
  return false;
}

/**
 * Function to determine the cell style based on the parameters.
 * @param {object} params - The parameters object containing data and metric information.
 * @return {object} The style object for the cell based on the conditions.
 */

const changeBorderBasedOnPeriodType = (params) => {
  if (params.data?.period_type) {
    return "7px solid grey";
  }
  return "4px solid white";
};
export function cellStyleFunc(params) {
  if (params.data?.metric === "spread") {
    return {
      border: "none",
      borderBottom: changeBorderBasedOnPeriodType(params),
      color: params?.value?.color,
      backgroundColor:
        params.colDef.headerName === "0.5"
          ? "var(--middle-cell-spread-row-color)"
          : "var(--spread-row-color)",
    };
  }

  if (params.data?.metric === "volatility") {
    return {
      backgroundColor: "var(--color-gray-12)",

      color: params?.data?.is_disabled === 1 ? "var(--color-gray-9)" : "white",
      borderRight: "1px solid #595959",
    };
  }
  return {
    border: "none",
    borderBottom: ".5px solid #595959",
    color: params?.value?.color,
  };
}
export function cellStyleFuncForVolSurfaceData(params) {
  return {
    border: "none",
    borderBottom: ".5px solid #595959",
    color: params?.value?.color,
  };
}
export function cellStyleDataVolSurfaceForFirstCol(params) {
  return {
    borderBottom: ".5px solid #595959",
    textAlign: "center",

    color: params?.data?.is_disabled === 1 ? "var(--color-gray-9)" : "white",
  };
}

/**
 * Generate cell style based on the data metric and tenor.
 * @param {object} params - The parameters for generating cell style
 * @return {object} The cell style object
 */
export function cellStyleForFirstCol(params) {

  if (params?.data?.metric === "spread") {
    return {
      borderBottom: changeBorderBasedOnPeriodType(params),
      textAlign: "center",
      color: "white",
    };

  } else if (params?.data?.metric === "volatility") {
    return {
      textAlign: "center",
      backgroundColor: "var(--color-gray-12)",
      color: params?.data?.is_disabled === 1 ? "var(--color-gray-9)" : "white",
    };
  } else if (params?.data?.tenor) {
    return {
      textAlign: "center",
    };
  }
}

/**
 * Formats the cell value.
 * @param {object} params - the parameters for formatting
 * @return {string|undefined} the formatted cell value, or undefined if the input value is falsy
 */
export const cellFormatter = (params) => {
  if (!params.value) return;

  if (typeof params.value === "object") {
    return params?.value?.value === null ? "" : formatNumber(params?.value?.value);
  }
  return params?.value === null ? "" : formatNumber(params?.value);
};

/**
 * Returns the style object for a cell based on the given metric.
 * @param {string} metric - The metric to determine the cell style
 * @return {object} The style object for the cell
 */
export const getCellStyle = (params) => ({
  border: "none",
  textAlign: "center",
  borderBottom:
    params?.data?.metric === "spread"
      ? changeBorderBasedOnPeriodType(params)
      : "none",
});

/**
 * Formats the value based on the given metric.
 * @param {object} params - The parameter object containing the data to be formatted
 * @return {string|number} The formatted value based on the metric
 */
export const valueFormatterForEstimateFirstCol = (params) => {
  const { metric, 0: value1, 2: valueSpread } = params?.data;
  if (metric === "spread") {
    if (typeof valueSpread === "object") {

      return valueSpread?.value === null ? "" :  formatNumber(valueSpread?.value);
    }
    return valueSpread === null ? "" :   formatNumber(valueSpread);
  }
  return metric === "strike" || metric === "volatility" ? value1 === null ? "" : formatNumber(value1) : "";
};

export const valueFormatterForEstimateSecondCol = (params) => {
  const { metric, 1: value2, 2: valueSpread } = params?.data;
  if (metric === "spread") {
    if (typeof valueSpread === "object") {

      return valueSpread?.value === null ? "" : formatNumber(valueSpread?.value);
    }
    return valueSpread === null ? "" : formatNumber(valueSpread);
  }
  return metric === "strike" || metric === "volatility" ? value2 === null ? "" : formatNumber(value2) : "";
};

/**
 * Updates the vol surface data with new data.
 * @param {Array} newData - The new data to update with.
 * @param {Array} volSurfaceTablesData - The original vol surface data.
 * @return {Array} The updated vol surface data.
 */
export const updateVolSurfaceData = (newData, oldData) => {
  const updatedData = oldData?.map((item) => {
    const updatedRow = newData.find((o) => o.id === item.id);

    if (updatedRow) {
      return updatedRow;
    }
    return item;
  });
  return updatedData;
};

/**
 * Updates the vol surface estimate data based on the provided new data.
 * @param {Array} newData - The new data to update the vol surface estimate with.
 * @param {Array} volSurfaceTablesData - The existing vol surface estimate data.
 * @return {Array} The updated vol surface estimate data.
 */
export const updateVolSurfaceEstimateData = (newData, volSurfaceTablesData) => {
  const updatedData = volSurfaceTablesData?.map((item) => {
    const updatedRow = newData.find((o) => o.id === item.id);
    if (updatedRow) {
      return updatedRow;
    }
    return item;
  });
  return updatedData;
};

/**
 * Updates the vol surface body data based on the event and selected filters.
 * @param {Object} event - The event object
 * @param {Object} selectedFilters - The selected filters object
 * @return {Object} An object containing the prepared body and body for underlying
 */
export const updateVolSurfaceBodyData = (event, selectedFilters) => {
  const node = event.node;
  const rowData = node.group ? node.aggData : node.data;

  let prepareBody = {
    vol_smile_id: rowData?.vol_smile_id,
    x_point: +event.colDef.headerName,
    new_volatility: +event.newValue,
    risk_free: selectedFilters?.risk_free || 0,
    comparison_surface_id: selectedFilters?.comparison_surface_id,
  };

  if (event.data.metric === "spread") {
    // Remove 'new_volatility' from prepareBody
    delete prepareBody.new_volatility;
    // Add 'new_spread' to prepareBody
    prepareBody.new_spread = +event.newValue;
  }

  const bodyForUnderlying = {
    vol_smile_id: rowData.vol_smile_id,
    underlying: +event.newValue,
    risk_free: selectedFilters?.risk_free || 0,
    comparison_surface_id: selectedFilters?.comparison_surface_id,
  };

  return {
    prepareBody,
    bodyForUnderlying,
  };
};

/**
 * Updates the graph data based on the response and volume surface data.
 * @param {Object} resArray - The response object
 * @param {Array} volSurfaceData - The volume surface data array
 * @return {void}
 */
export function updateGraph(resArray, volSurfaceData) {
  resArray?.data?.data?.graphs_data?.forEach((res) => {
    const blockToUpdate = volSurfaceData.find(
      (block) => block.block_id === res.block_id
    );

    if (blockToUpdate) {
      const graphToUpdate = blockToUpdate.block_graphs?.find(
        (graph) => graph.id === res.graph_id
      );

      if (graphToUpdate) {
        const liveVolSmileSeries = graphToUpdate.graphs[0].series?.find(
          (series) => series.user_vol_smile_id === res.vol_smile_id
        );

        if (liveVolSmileSeries) {
          liveVolSmileSeries.data = res.data;
          console.log(
            "Data updated successfully for graph_id:",
            graphToUpdate.id
          );
        } else {
          console.log(
            "Live Vol Smile series not found for graph_id:",
            graphToUpdate.id
          );
        }
      } else {
        console.log("Graph not found in block_id:", blockToUpdate?.block_id);
      }
    } else {
      console.log("Block not found for block_id:", res?.block_id);
    }
  });
}

export const extractSmileIds = (volSurfaceData) => {
  let smile_ids = [];
  volSurfaceData?.data?.map((block) => {
    let ids = block.block_graphs
      .map((graph) => graph.backend_data.user_vol_smile_id)
      .filter((key) => {
        if (key !== 0 || key !== null) {
          return key;
        }
      });
    smile_ids = [...smile_ids, ...ids];
  });

  return smile_ids;
};

export const onUpdateGraphTitleHelper = (
  volSurfaceControl,
  volSurfaceData,
  selectedFilters
) => {
  const smile_ids = extractSmileIds(volSurfaceData);
  let reqBody = {
    user_vol_smile_ids: smile_ids,
    title: volSurfaceControl,
    product_contract_style_id: selectedFilters["product_id"],
  };
  let querParams = {
    product_contract_style_id: selectedFilters["product_id"],
  };

  return { reqBody, querParams };
};



/**
 * Checks if the current row and next row have a different tenor.
 * @param {Object} params - The params object from AgGrid
 * @return {Boolean} If the current row and next row have a different tenor
 */
export const checkForDifferentTenor = (params) => {
   const currentRowData = params.data;
   const nextRowNode = params.node.rowIndex + 1;
   const nextRowData = params.api.getDisplayedRowAtIndex(nextRowNode)?.data;
   const isDifferentTenor = currentRowData?.tenor !== nextRowData?.tenor;
   return isDifferentTenor;
}

/**
 * Returns the CSS text-align value for the given column.
 * @param {String} col - The column name.
 * @return {String} The CSS text-align value for the column.
 * If the column is 'tenor' or 'underlying', the value is 'center', otherwise it is 'left'.
 */
export const getAlignment = (col) => {
  const columnsWithCenterAlignment = ["tenor", "underlying"];
  return columnsWithCenterAlignment.includes(col) ? "center" : "left";
};

/**
 * Returns a CSS object with the border style for a cell in the AgGrid.
 * If `isDifferentTenor` is true, the border is set to grey and the bottom border is set to 7px.
 * Otherwise, the border is set to null and the bottom border is set to 1px solid var(--border-color)
 * for cells that are not in the 'tenor' or 'underlying' column.
 * @param {Boolean} isDifferentTenor - If the current row and next row have a different tenor.
 * @param {String} col - The column name.
 * @return {Object} A CSS object with the border style.
 */
export const getBorderColor = (isDifferentTenor, col) => {
  if (isDifferentTenor) {
    return {
      border: "none",
      borderBottom: "7px solid grey",
    };
  } else {
    return {
      border: null,
      borderBottom:
        col === "tenor" || col === "underlying"
          ? "none"
          : "1px solid var(--border-color)",
    };
  }
};

/**
 * Returns the background color for a cell in the AgGrid.
 * If the column is 'underlying' and the row's key is 16, or the column is 'vol',
 * the background color is set to var(--color-gray-12). Otherwise, it is set to null.
 * @param {String} col - The column name.
 * @param {Object} data - The data for the current row.
 * @return {String} The background color for the cell.
 */
export const getBackgroundColor = (col, data) => {
  return (col === "underlying" && data.show_tenor_and_ul) || col === "premium" || col ==="strike"
    ? "var(--color-gray-12)"
    : null;
};

export const getColor = (col) => {
  const colorMapping = {
    delta: "#E1A2C7",
    strike: "#C9B9CB",
    vol: "#8CA9BB",
  };
  return colorMapping[col] || "";
};

/**
 * Generate cell style for sticky strike table based on the column and row data.
 * The style object returned will have the following properties:
 * - textAlign: The alignment of the text based on the column.
 * - border: The border style based on whether the current row is a different tenor.
 * - backgroundColor: The background color based on the column and row key.
 * - borderRight: The right border style.
 * - color: The text color based on the column.
 * @param {Object} params - The parameters passed to the cellStyle function.
 * @return {Object} The style object for the cell.
 */
export const stickyStrikeCellStyle = (params) => {
  const { colDef, data } = params;
  const col = colDef.field;
  const isDifferentTenor = checkForDifferentTenor(params);
  return {
    textAlign: getAlignment(col),
    ...getBorderColor(isDifferentTenor, col),
    backgroundColor: params.node.selected
      ? null
      : getBackgroundColor(col, data),
    borderRight: "1px solid var(--border-color)",
    color: getColor(col),
  };
};

 export const onGridReady = (params) => {
   params.api.sizeColumnsToFit();
 };